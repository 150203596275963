import React from 'react'
import style from './img.module.scss'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './img.css';

export default function ImageCarousel({ data }) {

    return (
        <div className={style.imageContainer}>
            <Carousel
                autoPlay
                showIndicators={false}
                stopOnHover
                dynamicHeight={false}
                showThumbs={data.length > 1}
                showStatus={data.length > 1}
            >
                {
                    data.map((img, index) => (
                        <img
                            className={style.img}
                            key={"ProductImage" + index}
                            alt='productImage'
                            src={img}
                        />
                    ))
                }
            </Carousel>
        </div>
    )
}
