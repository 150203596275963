export const CafeList = {
  cafe1: () => {
    return (
      <>
        <table>
          <tr className="title">
            <td colSpan={6}>SBARRO</td>
            <td></td>
            <td></td>
          </tr>
          <tr className="head">
            <td colSpan={3}>MENU</td>
            <td>PRICE</td>
            <td>GM/ML</td>
          </tr>
          <tr>
            <td colSpan={3}>NEW YORK CHEESE PIZZA</td>
            <td className="ft-Wt-700">200</td>
            <td className="ft-Wt-700">145 GMS</td>
          </tr>
          <tr>
            <td colSpan={3}>VEG DELIGHT</td>
            <td className="ft-Wt-700">200</td>
            <td className="ft-Wt-700">175 GMS</td>
          </tr>
          <tr>
            <td colSpan={3}>PANEER DELIGHT</td>
            <td className="ft-Wt-700">200</td>
            <td className="ft-Wt-700">180 GMS</td>
          </tr>
          <tr>
            <td colSpan={3}>VEG SUPREME</td>
            <td className="ft-Wt-700">200</td>
            <td className="ft-Wt-700">180 GMS</td>
          </tr>
          <tr>
            <td colSpan={3}>MAKHANI PANEER</td>
            <td className="ft-Wt-700">200</td>
            <td className="ft-Wt-700">180 GMS</td>
          </tr>
          <tr>
            <td colSpan={3}>VEG MEXICANA</td>
            <td className="ft-Wt-700">200</td>
            <td className="ft-Wt-700">175 GMS</td>
          </tr>
          <tr className="subHead">
            <td colSpan={6}>CINNABON</td>
          </tr>
          <tr>
            <td colSpan={3}>CINNABON</td>
            <td className="ft-Wt-700">150</td>
            <td className="ft-Wt-700">100 GMS</td>
          </tr>
          <tr>
            <td colSpan={3}>CHOCOLATE BON</td>
            <td className="ft-Wt-700">150</td>
            <td className="ft-Wt-700">100 GMS</td>
          </tr>
          <tr>
            <td colSpan={3}>ROLL-ON-GO</td>
            <td className="ft-Wt-700">150</td>
            <td className="ft-Wt-700">110 GMS</td>
          </tr>
          <tr>
            <td>CHOCOLATE ON-THE-GO</td>
            <td className="ft-Wt-700">150</td>
            <td className="ft-Wt-700">110 GMS</td>
          </tr>
          <tr class="spacer">
          </tr>
        </table>
        <table>
          <tr className="title">
            <td colSpan={3}>CAFÉ COFFEE DAY</td>
          </tr>
          <tr className="head">
            <td>MENU</td>
            <td>PRICE</td>
            <td>GM/ML</td>
          </tr>
          <tr className="spacer">
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>BEVERAGES</td>
          </tr>
          <tr>
            <td>CAPPUCCINO</td>
            <td className="ft-Wt-700">200</td>
            <td className="ft-Wt-700">250 ML</td>
          </tr>
          <tr>
            <td>CAFÉ LATTE</td>
            <td className="ft-Wt-700">200</td>
            <td className="ft-Wt-700">250 ML</td>
          </tr>
          <tr>
            <td>CAFÉ MOCHA</td>
            <td className="ft-Wt-700">200</td>
            <td className="ft-Wt-700">250 ML</td>
          </tr>
          <tr>
            <td>AMERICANO</td>
            <td className="ft-Wt-700">200</td>
            <td className="ft-Wt-700">250 ML</td>
          </tr>
          <tr>
            <td>HOT CHOCOLATE</td>
            <td className="ft-Wt-700">150</td>
            <td className="ft-Wt-700">250 ML</td>
          </tr>
          <tr>
            <td>MASALA TEA</td>
            <td className="ft-Wt-700">50</td>
            <td className="ft-Wt-700">140 ML</td>
          </tr>
          <tr>
            <td>GREEN TEA</td>
            <td className="ft-Wt-700">50</td>
            <td className="ft-Wt-700">140 ML</td>
          </tr>
          <tr>
            <td>COLD COFFEE</td>
            <td className="ft-Wt-700">200</td>
            <td className="ft-Wt-700">300 ML</td>
          </tr>
          <tr>
            <td>ICE CHOCOLATE</td>
            <td className="ft-Wt-700">150</td>
            <td className="ft-Wt-700">350 ML</td>
          </tr>
          <tr>
            <td>OREO SHAKE</td>
            <td className="ft-Wt-700">200</td>
            <td className="ft-Wt-700">350 ML</td>
          </tr>
          <tr className="spacer">
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>SNACKS</td>
          </tr>
          <tr>
            <td>PUNJABI SAMOSA (2 PCS)</td>
            <td className="ft-Wt-700">100</td>
            <td className="ft-Wt-700">130 GMS</td>
          </tr>
          <tr>
            <td>MUMBAI MASALA GRILLED S/W</td>
            <td className="ft-Wt-700">200</td>
            <td className="ft-Wt-700">200 GMS</td>
          </tr>
          <tr>
            <td>CHEESY MASALA WRAP (JAIN)</td>
            <td className="ft-Wt-700">200</td>
            <td className="ft-Wt-700">200 GMS</td>
          </tr>
          <tr>
            <td>PANEER TIKKA WRAP</td>
            <td className="ft-Wt-700">200</td>
            <td className="ft-Wt-700">200 GMS</td>
          </tr>
          <tr>
            <td>VEG MEXICAN CHEESE PANINI S/W</td>
            <td className="ft-Wt-700">200</td>
            <td className="ft-Wt-700">200 GMS</td>
          </tr>
          <tr>
            <td>VEG CHEESE GRILL S/W (JAIN)</td>
            <td className="ft-Wt-700">200</td>
            <td className="ft-Wt-700">200 GMS</td>
          </tr>
          <tr>
            <td>MELTED TANDOORI PANEER PANINI S/W</td>
            <td className="ft-Wt-700">250</td>
            <td className="ft-Wt-700">200 GMS</td>
          </tr>
          <tr>
            <td>MEXICAN CHEESE BURGER</td>
            <td className="ft-Wt-700">200</td>
            <td className="ft-Wt-700">150 GMS</td>
          </tr>
          <tr>
            <td>CLASSIC MARGHERITA PIZZA</td>
            <td className="ft-Wt-700">250</td>
            <td className="ft-Wt-700">200 GMS</td>
          </tr>
        </table>
      </>

    );
  },
  cafe2: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>SUBWAY</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
          <td>GM/ML</td>
        </tr>

        <tr className="subHead">
          <td colSpan={3}>SANDWICHES</td>
        </tr>
        <tr>
          <td>VEGGIE DELITE</td>
          <td className="ft-Wt-700">250</td>
          <td className="ft-Wt-700">220 GM</td>
        </tr>
        <tr>
          <td>ALOO PATTY</td>
          <td className="ft-Wt-700">300</td>
          <td className="ft-Wt-700">275 GM</td>
        </tr>
        <tr>
          <td>PANEER ACHARI</td>
          <td className="ft-Wt-700">300</td>
          <td className="ft-Wt-700">240 GM</td>
        </tr>
        <tr>
          <td>CHEESE CHILLI</td>
          <td className="ft-Wt-700">300</td>
          <td className="ft-Wt-700">250 GM</td>
        </tr>
        <tr>
          <td>THE MEXICAN</td>
          <td className="ft-Wt-700">300</td>
          <td className="ft-Wt-700">250 GM</td>
        </tr>

        <tr className="subHead">
          <td colSpan={3}>WRAPS</td>
        </tr>
        <tr>
          <td>VEGGIE DELITE</td>
          <td className="ft-Wt-700">290</td>
          <td className="ft-Wt-700">334 GM</td>
        </tr>
        <tr>
          <td>PANEER TIKKA</td>
          <td className="ft-Wt-700">320</td>
          <td className="ft-Wt-700">350 GM</td>
        </tr>
        <tr>
          <td>ALOO PATTY</td>
          <td className="ft-Wt-700">320</td>
          <td className="ft-Wt-700">340 GM</td>
        </tr>
        <tr>
          <td>MEXICAN PATTY</td>
          <td className="ft-Wt-700">320</td>
          <td className="ft-Wt-700">350 GM</td>
        </tr>

        <tr className="subHead">
          <td colSpan={3}>SALADS</td>
        </tr>
        <tr>
          <td>VEGGIE DELITE</td>
          <td className="ft-Wt-700">290</td>
          <td className="ft-Wt-700">365 GM</td>
        </tr>
        <tr>
          <td>PANEER TIKKA</td>
          <td className="ft-Wt-700">280</td>
          <td className="ft-Wt-700">425 GM</td>
        </tr>
        <tr>
          <td>ALOO PATTY</td>
          <td className="ft-Wt-700">280</td>
          <td className="ft-Wt-700">420 GM</td>
        </tr>
        <tr>
          <td>MEXICAN PATTY</td>
          <td className="ft-Wt-700">280</td>
          <td className="ft-Wt-700">425 GM</td>
        </tr>

        <tr className="subHead">
          <td colSpan={3}>CAFÉ</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>HOT BEVERAGES</td>
        </tr>
        <tr>
          <td>CAPPUCCINO</td>
          <td className="ft-Wt-700">180</td>
          <td className="ft-Wt-700">140 ML</td>
        </tr>
        <tr>
          <td>LATTE</td>
          <td className="ft-Wt-700">180</td>
          <td className="ft-Wt-700">140 ML</td>
        </tr>
        <tr>
          <td>ESPRESSO</td>
          <td className="ft-Wt-700">150</td>
          <td className="ft-Wt-700">30 ML / 60 ML</td>
        </tr>
        <tr>
          <td>AMERICANO</td>
          <td className="ft-Wt-700">150</td>
          <td className="ft-Wt-700">140 ML</td>
        </tr>
        <tr>
          <td>MALASA TEA</td>
          <td className="ft-Wt-700">100</td>
          <td className="ft-Wt-700">100 ML</td>
        </tr>
        <tr>
          <td>LEMON / GREEN TEA</td>
          <td className="ft-Wt-700">100</td>
          <td className="ft-Wt-700">100 ML</td>
        </tr>

        <tr className="subHead">
          <td colSpan={3}>COLD BEVERAGES</td>
        </tr>
        <tr>
          <td>CHOCOLATE MILKSHAKE</td>
          <td className="ft-Wt-700">250</td>
          <td className="ft-Wt-700">195 ML</td>
        </tr>
        <tr>
          <td>STRAWBERRY MILKSHAKE</td>
          <td className="ft-Wt-700">250</td>
          <td className="ft-Wt-700">195 ML</td>
        </tr>
        <tr>
          <td>RAW COCONUT WATER</td>
          <td className="ft-Wt-700">150</td>
          <td className="ft-Wt-700">200 ML</td>
        </tr>
        <tr>
          <td>RAW ALOE VERA LEMONADE</td>
          <td className="ft-Wt-700">150</td>
          <td className="ft-Wt-700">200 ML</td>
        </tr>
        <tr>
          <td>RAW ALPHONSO</td>
          <td className="ft-Wt-700">150</td>
          <td className="ft-Wt-700">175 ML</td>
        </tr>
        <tr>
          <td>RAW MIX FRUIT</td>
          <td className="ft-Wt-700">150</td>
          <td className="ft-Wt-700">175 ML</td>
        </tr>

        <tr className="subHead">
          <td colSpan={3}>COOKIES</td>
        </tr>
        <tr>
          <td>DOUBLE DARK CHUNK</td>
          <td className="ft-Wt-700">120</td>
          <td className="ft-Wt-700">40 GM</td>
        </tr>
      </table>
    );
  },
  cafe3: () => {
    return (
      <>
        <table>
          <tr className="title">
            <td colSpan={3}>NEW YORK BURRITO</td>
          </tr>
          <tr className="head">
            <td>MENU</td>
            <td>PRICE</td>
            <td>GM/ML</td>
          </tr>
          <tr>
            <td>MEXICAN BOWL</td>
            <td className="ft-Wt-700">330</td>
            <td className="ft-Wt-700">300 GMS</td>
          </tr>
          <tr>
            <td>MEXICAN BURRITO</td>
            <td className="ft-Wt-700">330</td>
            <td className="ft-Wt-700">300 GMS</td>
          </tr>
          <tr>
            <td>MEXICAN PIZZA</td>
            <td className="ft-Wt-700">350</td>
            <td className="ft-Wt-700">300 GMS</td>
          </tr>
          <tr>
            <td>HARD-SHELL TACOS (3 PCS)</td>
            <td className="ft-Wt-700">250</td>
            <td className="ft-Wt-700">200 GMS</td>
          </tr>
          <tr>
            <td>REGULAR NACHOS</td>
            <td className="ft-Wt-700">230</td>
            <td className="ft-Wt-700">180 GMS</td>
          </tr>
          <tr class="spacer"></tr>
        </table>
        <table>
          <tr className="title">
            <td colSpan={3}>TEA POST</td>
          </tr>
          <tr className="head">
            <td>MENU</td>
            <td>PRICE</td>
            <td>GM/ML</td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>TEA</td>
          </tr>
          <tr>
            <td>MASALA TEA</td>
            <td className="ft-Wt-700">60</td>
            <td className="ft-Wt-700">120 ML</td>
          </tr>
          <tr>
            <td>GINGER TEA</td>
            <td className="ft-Wt-700">80</td>
            <td className="ft-Wt-700">120 ML</td>
          </tr>
          <tr>
            <td>ELAICHI TEA</td>
            <td className="ft-Wt-700">80</td>
            <td className="ft-Wt-700">120 ML</td>
          </tr>
          <tr>
            <td>GUD WALI CHAI</td>
            <td className="ft-Wt-700">80</td>
            <td className="ft-Wt-700">120 ML</td>
          </tr>
          <tr>
            <td>KULHAD CHAI</td>
            <td className="ft-Wt-700">100</td>
            <td className="ft-Wt-700">120 ML</td>
          </tr>
          <tr>
            <td>SAFFRON TEA</td>
            <td className="ft-Wt-700">120</td>
            <td className="ft-Wt-700">120 ML</td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>HOT BOURNVITA</td>
          </tr>
          <tr>
            <td>HOT BOURNVITA</td>
            <td className="ft-Wt-700">120</td>
            <td className="ft-Wt-700">180 ML</td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>COFFEE</td>
          </tr>
          <tr>
            <td>HOT COFFEE</td>
            <td className="ft-Wt-700">60</td>
            <td className="ft-Wt-700">120 ML</td>
          </tr>
          <tr>
            <td>BLACK COFFEE</td>
            <td className="ft-Wt-700">60</td>
            <td className="ft-Wt-700">120 ML</td>
          </tr>
          <tr>
            <td>COLD COFFEE</td>
            <td className="ft-Wt-700">150</td>
            <td className="ft-Wt-700">300 ML</td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>ICE TEA</td>
          </tr>
          <tr>
            <td>LEMON ICE TEA</td>
            <td className="ft-Wt-700">150</td>
            <td className="ft-Wt-700">300 ML</td>
          </tr>
          <tr>
            <td>PEACH ICE TEA</td>
            <td className="ft-Wt-700">150</td>
            <td className="ft-Wt-700">300 ML</td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>TEA WITHOUT MILK</td>
          </tr>
          <tr>
            <td>GREEN TEA</td>
            <td className="ft-Wt-700">60</td>
            <td className="ft-Wt-700">180 ML</td>
          </tr>
          <tr>
            <td>BLACK TEA</td>
            <td className="ft-Wt-700">60</td>
            <td className="ft-Wt-700">180 ML</td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>NASHTA</td>
          </tr>
          <tr>
            <td>VEG PUFF</td>
            <td className="ft-Wt-700">60</td>
            <td className="ft-Wt-700">100 GMS</td>
          </tr>
          <tr>
            <td>THEPLA (J)</td>
            <td className="ft-Wt-700">100</td>
            <td className="ft-Wt-700">80 GMS</td>
          </tr>
          <tr>
            <td>MASKA BUN (J)</td>
            <td className="ft-Wt-700">80</td>
            <td className="ft-Wt-700">120 GMS</td>
          </tr>
        </table>
      </>
    );
  },
  cafe4: () => {
    return (
      <>
        <table>
          <tr className="title">
            <td colSpan={3}>SOCIAL</td>
          </tr>
          <tr className="head">
            <td>MENU</td>
            <td>PRICE</td>
            <td>GM/ML</td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>BEVERAGE</td>
          </tr>
          <tr>
            <td>COFFEE</td>
            <td className="ft-Wt-700">40</td>
            <td className="ft-Wt-700">120 ML</td>
          </tr>
          <tr>
            <td>MASALA CHAI</td>
            <td className="ft-Wt-700">30</td>
            <td className="ft-Wt-700">120 ML</td>
          </tr>
          <tr>
            <td>SWEET LASSI</td>
            <td className="ft-Wt-700">150</td>
            <td className="ft-Wt-700">315 ML</td>
          </tr>
          <tr>
            <td>FRESH LIME SODA / WATER</td>
            <td className="ft-Wt-700">150</td>
            <td className="ft-Wt-700">330 ML</td>
          </tr>
          <tr>
            <td>MOCKTAILS - SPICED GUAVA / BLUE LAGOON</td>
            <td className="ft-Wt-700">150</td>
            <td className="ft-Wt-700">330 ML</td>
          </tr>
          <tr>
            <td>REDBULL</td>
            <td className="ft-Wt-700">200</td>
            <td className="ft-Wt-700">250 ML</td>
          </tr>
          <tr>
            <td>COKE</td>
            <td className="ft-Wt-700">40</td>
            <td className="ft-Wt-700">300 ML</td>
          </tr>
          <tr>
            <td>SPRITE</td>
            <td className="ft-Wt-700">40</td>
            <td className="ft-Wt-700">300 ML</td>
          </tr>
          <tr>
            <td>DIET COKE</td>
            <td className="ft-Wt-700">40</td>
            <td className="ft-Wt-700">300 ML</td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>BREAKFAST</td>
          </tr>
          <tr>
            <td>ALOO PARATHA WITH CURD & PICKLE</td>
            <td className="ft-Wt-700">150</td>
            <td className="ft-Wt-700">350 GRAM [1 NO ALOO PARATHA + CURD + MANGO PICKLE] MEAL BOX</td>
          </tr>
          <tr>
            <td>PANEER PARATHA WITH CURD [JAIN]</td>
            <td className="ft-Wt-700">150</td>
            <td className="ft-Wt-700">
              350 GRAM [1 NO PANEER PARATHA + CURD + GREEN CHUTNEY] MEAL BOX
            </td>
          </tr>
          <tr>
            <td>PUNJABI SAMOSA WITH CHUTNEY</td>
            <td className="ft-Wt-700">150</td>
            <td className="ft-Wt-700">250 GRAM</td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>SNACKS</td>
          </tr>
          <tr>
            <td>BOMBAY BACHELOR SANDWICH</td>
            <td className="ft-Wt-700">250</td>
            <td className="ft-Wt-700">350 GRAM</td>
          </tr>
          <tr>
            <td>ALOO TIKKI BURGER</td>
            <td className="ft-Wt-700">250</td>
            <td className="ft-Wt-700">250 GRAM</td>
          </tr>
          <tr>
            <td>CHINESE BHEL</td>
            <td className="ft-Wt-700">200</td>
            <td className="ft-Wt-700">250 GRAM</td>
          </tr>
          <tr class="spacer"></tr>
        </table>

        <table>
          <tr className="title">
            <td colSpan={3}>DOPE COFFEE</td>
          </tr>
          <tr className="head">
            <td>MENU</td>
            <td>PRICE</td>
            <td>GM/ML</td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>HOT BEVERAGE</td>
          </tr>
          <tr>
            <td>AMERICANO</td>
            <td className="ft-Wt-700">150</td>
            <td className="ft-Wt-700">170 ML</td>
          </tr>
          <tr>
            <td>CAPPUCCINO</td>
            <td className="ft-Wt-700">150</td>
            <td className="ft-Wt-700">170 ML</td>
          </tr>
          <tr>
            <td>LATTE</td>
            <td className="ft-Wt-700">150</td>
            <td className="ft-Wt-700">170 ML</td>
          </tr>
          <tr>
            <td>MOCHA</td>
            <td className="ft-Wt-700">150</td>
            <td className="ft-Wt-700">170 ML</td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>ICED/COLD</td>
          </tr>
          <tr>
            <td>ICED AMERICANO</td>
            <td className="ft-Wt-700">150</td>
            <td className="ft-Wt-700">180 ML</td>
          </tr>
          <tr>
            <td>ICED LATTE</td>
            <td className="ft-Wt-700">150</td>
            <td className="ft-Wt-700">180 ML</td>
          </tr>
          <tr>
            <td>COLD BREW</td>
            <td className="ft-Wt-700">150</td>
            <td className="ft-Wt-700">180 ML</td>
          </tr>
          <tr>
            <td>DOPE'S COLD COFFEE</td>
            <td className="ft-Wt-700">150</td>
            <td className="ft-Wt-700">180 ML</td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>TEA</td>
          </tr>
          <tr>
            <td>GREEN TEA</td>
            <td className="ft-Wt-700">100</td>
            <td className="ft-Wt-700">180 ML</td>
          </tr>
          <tr>
            <td>GINGER TEA</td>
            <td className="ft-Wt-700">100</td>
            <td className="ft-Wt-700">180 ML</td>
          </tr>
          <tr>
            <td>MASALA CHAI</td>
            <td className="ft-Wt-700">100</td>
            <td className="ft-Wt-700">180 ML</td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>BEVERAGE ADD ONS (FLAVOURS)</td>
          </tr>
          <tr>
            <td>CHOCOLATE/HAZELNUT/CARAMEL/VANILLA</td>
            <td className="ft-Wt-700">30</td>
            <td className="ft-Wt-700">15 ML</td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>EATS</td>
          </tr>
          <tr>
            <td>BUTTER CROISSANT</td>
            <td className="ft-Wt-700">150</td>
            <td className="ft-Wt-700">100 GMS</td>
          </tr>
          <tr>
            <td>PAIN AU CHOCOLATE</td>
            <td className="ft-Wt-700">150</td>
            <td className="ft-Wt-700">120 GMS</td>
          </tr>
        </table>
      </>
    );
  },
  cafe5: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>CAFÉ COFFEE DAY</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
          <td>GM/ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>BEVERAGES</td>
        </tr>
        <tr>
          <td>CAPPUCCINNO</td>
          <td className="ft-Wt-700">200</td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
        <tr>
          <td>CAFÉ LATTE</td>
          <td className="ft-Wt-700">200</td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
        <tr>
          <td>CAFÉ MOCHA</td>
          <td className="ft-Wt-700">200</td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
        <tr>
          <td>AMERICANO</td>
          <td className="ft-Wt-700">200</td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
        <tr>
          <td>HOT CHOCOLATE</td>
          <td className="ft-Wt-700">150</td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
        <tr>
          <td>MASALA TEA</td>
          <td className="ft-Wt-700">50</td>
          <td className="ft-Wt-700">140 ML</td>
        </tr>
        <tr>
          <td>GREEN TEA</td>
          <td className="ft-Wt-700">50</td>
          <td className="ft-Wt-700">140 ML</td>
        </tr>
        <tr>
          <td>COLD COFFEE</td>
          <td className="ft-Wt-700">200</td>
          <td className="ft-Wt-700">300 ML</td>
        </tr>
        <tr>
          <td>ICE CHOCOLATE</td>
          <td className="ft-Wt-700">150</td>
          <td className="ft-Wt-700">350 ML</td>
        </tr>
        <tr>
          <td>ORIO SHAKE</td>
          <td className="ft-Wt-700">200</td>
          <td className="ft-Wt-700">350 ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>SNACKS</td>
        </tr>
        <tr>
          <td>PUNJABI SAMOSA (2PCS)</td>
          <td className="ft-Wt-700">100</td>
          <td className="ft-Wt-700">130 GMS</td>
        </tr>
        <tr>
          <td>MUMBAI MASALA GRILLED S/W</td>
          <td className="ft-Wt-700">200</td>
          <td className="ft-Wt-700">200 GMS</td>
        </tr>
        <tr>
          <td>CHEESY MASALA WRAP (JAIN)</td>
          <td className="ft-Wt-700">200</td>
          <td className="ft-Wt-700">200 GMS</td>
        </tr>
        <tr>
          <td>PANEER TIKKA WRAP</td>
          <td className="ft-Wt-700">200</td>
          <td className="ft-Wt-700">200 GMS</td>
        </tr>
        <tr>
          <td>VEG MAXICAN CHEESE PANINI S/W</td>
          <td className="ft-Wt-700">200</td>
          <td className="ft-Wt-700">200 GMS</td>
        </tr>
        <tr>
          <td>VEG CHEESE GRILL S/W (JAIN)</td>
          <td className="ft-Wt-700">200</td>
          <td className="ft-Wt-700">200 GMS</td>
        </tr>
        <tr>
          <td>MELTED TANDOORI PANEER PANINI S/W</td>
          <td className="ft-Wt-700">250</td>
          <td className="ft-Wt-700">200 GMS</td>
        </tr>
        <tr>
          <td>MAXICAN CHEESE BURGER</td>
          <td className="ft-Wt-700">200</td>
          <td className="ft-Wt-700">150 GMS</td>
        </tr>
        <tr>
          <td>CLASSIC MARGHERITA PIZZA</td>
          <td className="ft-Wt-700">250</td>
          <td className="ft-Wt-700">200 GMS</td>
        </tr>
      </table>
    );
  },
  cafe6: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>HIGHNESS PANINI</td>
        </tr>
        <tr className="header">
          <td>MENU</td>
          <td>PRICE</td>
          <td>GM/ML</td>
        </tr>
        <tr className="section">
          <td colSpan={3}>SNACKS &amp; BAKERY</td>
        </tr>
        <tr>
          <td>MEXICAN (JAIN/REGULAR)</td>
          <td className="ft-Wt-700">200</td>
          <td className="ft-Wt-700">150 GMS</td>
        </tr>
        <tr>
          <td>PANEER TIKKA</td>
          <td className="ft-Wt-700">200</td>
          <td className="ft-Wt-700">150 GMS</td>
        </tr>
        <tr>
          <td>CHEESE MELT PANINI (JAIN/REGULAR)</td>
          <td className="ft-Wt-700">250</td>
          <td className="ft-Wt-700">160 GMS</td>
        </tr>
        <tr>
          <td>CHEESE MAYO GARLIC BREAD</td>
          <td className="ft-Wt-700">180</td>
          <td className="ft-Wt-700">100 GMS</td>
        </tr>
        <tr>
          <td>VEG GRILLED SANDWICH (JAIN/REGULAR)</td>
          <td className="ft-Wt-700">250</td>
          <td className="ft-Wt-700">150 GMS</td>
        </tr>
        <tr>
          <td>MELTED SANDWICH (JAIN/REGULAR)</td>
          <td className="ft-Wt-700">280</td>
          <td className="ft-Wt-700">160 GMS</td>
        </tr>
        <tr>
          <td>MASKA BUN</td>
          <td className="ft-Wt-700">80</td>
          <td className="ft-Wt-700">50 GMS</td>
        </tr>
        <tr>
          <td>JAM BUN</td>
          <td className="ft-Wt-700">80</td>
          <td className="ft-Wt-700">50 GMS</td>
        </tr>
        <tr className="section">
          <td colSpan={3}>ROLL</td>
        </tr>
        <tr>
          <td>PANEER TIKKA ROLL</td>
          <td className="ft-Wt-700">200</td>
          <td className="ft-Wt-700">150 GMS</td>
        </tr>
        <tr className="section">
          <td colSpan={3}>HOT BEVERAGES</td>
        </tr>
        <tr>
          <td>REGULAR ADRAK TEA</td>
          <td className="ft-Wt-700">50</td>
          <td className="ft-Wt-700">100 ML</td>
        </tr>
        <tr>
          <td>MASALA TEA</td>
          <td className="ft-Wt-700">70</td>
          <td className="ft-Wt-700">150 ML</td>
        </tr>
        <tr>
          <td>BLACK TEA</td>
          <td className="ft-Wt-700">50</td>
          <td className="ft-Wt-700">100 ML</td>
        </tr>
        <tr>
          <td>LEMON TEA</td>
          <td className="ft-Wt-700">70</td>
          <td className="ft-Wt-700">150 ML</td>
        </tr>
        <tr>
          <td>GREEN TEA</td>
          <td className="ft-Wt-700">70</td>
          <td className="ft-Wt-700">150 ML</td>
        </tr>
        <tr>
          <td>CAPPUCCINO</td>
          <td className="ft-Wt-700">150</td>
          <td className="ft-Wt-700">200 ML</td>
        </tr>
        <tr>
          <td>ESPRESSO</td>
          <td className="ft-Wt-700">150</td>
          <td className="ft-Wt-700">200 ML</td>
        </tr>
        <tr>
          <td>FILTER COFFEE</td>
          <td className="ft-Wt-700">70</td>
          <td className="ft-Wt-700">100 ML</td>
        </tr>
        <tr className="section">
          <td colSpan={3}>COLD BEVERAGES</td>
        </tr>
        <tr>
          <td>SIGNATURE MOCHA COLD COFFEE</td>
          <td className="ft-Wt-700">200</td>
          <td className="ft-Wt-700">200 ML</td>
        </tr>
        <tr>
          <td>SIGNATURE COLD COFFEE</td>
          <td className="ft-Wt-700">180</td>
          <td className="ft-Wt-700">350 ML</td>
        </tr>
        <tr>
          <td>SIGNATURE ICE AMERICANO</td>
          <td className="ft-Wt-700">200</td>
          <td className="ft-Wt-700">300 ML</td>
        </tr>
        <tr>
          <td>LEMON ICE TEA</td>
          <td className="ft-Wt-700">180</td>
          <td className="ft-Wt-700">300 ML</td>
        </tr>
        <tr>
          <td>PEACH ICE TEA</td>
          <td className="ft-Wt-700">180</td>
          <td className="ft-Wt-700">300 ML</td>
        </tr>
        <tr className="section">
          <td colSpan={3}>PIZZA</td>
        </tr>
        <tr>
          <td>MARGARITA PIZZA (JAIN/REGULAR)</td>
          <td className="ft-Wt-700">250</td>
          <td className="ft-Wt-700">140 GMS</td>
        </tr>
        <tr>
          <td>PEPPY PANEER PIZZA (JAIN/REGULAR)</td>
          <td className="ft-Wt-700">300</td>
          <td className="ft-Wt-700">170 GMS</td>
        </tr>
      </table>
    );
  },
  cafe7: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>HATTI KAAPI</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
          <td>GM/ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>HOT BEVERAGES (180 ML)</td>
        </tr>
        <tr>
          <td>KAAPI MEDIUM</td>
          <td className="ft-Wt-700">100</td>
          <td className="ft-Wt-700">180ML</td>
        </tr>
        <tr>
          <td>KAAPI LARGE</td>
          <td className="ft-Wt-700">120</td>
          <td className="ft-Wt-700">250ML</td>
        </tr>
        <tr>
          <td>BELLA KAAPI(JAGGERY KAAPI)*BEST SELLER - MEDIUM</td>
          <td className="ft-Wt-700">110</td>
          <td className="ft-Wt-700">180 ML</td>
        </tr>
        <tr>
          <td>BELLA KAAPI(JAGGERY KAAPI)*BEST SELLER - LARGE</td>
          <td className="ft-Wt-700">130</td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>

        <tr className="subHead">
          <td colSpan={3}>CHAIS</td>
        </tr>
        <tr>
          <td>CHAI MEDIUM</td>
          <td className="ft-Wt-700">80</td>
          <td className="ft-Wt-700">180ML</td>
        </tr>
        <tr>
          <td>CHAI LARGE</td>
          <td className="ft-Wt-700">100</td>
          <td className="ft-Wt-700">250ML</td>
        </tr>
        <tr>
          <td>LEMON CHAI MEDIUM</td>
          <td className="ft-Wt-700">80</td>
          <td className="ft-Wt-700">180ML</td>
        </tr>
        <tr>
          <td>LEMON CHAI LARGE</td>
          <td className="ft-Wt-700">100</td>
          <td className="ft-Wt-700">250ML</td>
        </tr>
        <tr>
          <td>GINGER CHAI MEDIUM</td>
          <td className="ft-Wt-700">80</td>
          <td className="ft-Wt-700">180ML</td>
        </tr>
        <tr>
          <td>GINGER CHAI LARGE</td>
          <td className="ft-Wt-700">100</td>
          <td className="ft-Wt-700">250ML</td>
        </tr>

        <tr className="subHead">
          <td colSpan={3}>OTHERS</td>
        </tr>
        <tr>
          <td>BADAM MILK MEDIUM</td>
          <td className="ft-Wt-700">100</td>
          <td className="ft-Wt-700">180ML</td>
        </tr>
        <tr>
          <td>BADAM MILK LARGE</td>
          <td className="ft-Wt-700">130</td>
          <td className="ft-Wt-700">250ML</td>
        </tr>

        <tr className="subHead">
          <td colSpan={3}>COLD BEVERAGES</td>
        </tr>
        <tr>
          <td>COLD COFFEE CLASSIC</td>
          <td className="ft-Wt-700">180</td>
          <td className="ft-Wt-700">300ML</td>
        </tr>
        <tr>
          <td>COLD COFFEE FLAVOURED</td>
          <td className="ft-Wt-700">200</td>
          <td className="ft-Wt-700">300ML</td>
        </tr>

        <tr className="subHead">
          <td colSpan={3}>SNACKS</td>
        </tr>
        <tr>
          <td>KOREAN BUN</td>
          <td className="ft-Wt-700">130</td>
          <td className="ft-Wt-700">100 GM</td>
        </tr>
        <tr>
          <td>BLUEBERRY MUFFINS</td>
          <td className="ft-Wt-700">100</td>
          <td className="ft-Wt-700">60 GM</td>
        </tr>
        <tr>
          <td>BANANA WALNUT CAKE</td>
          <td className="ft-Wt-700">150</td>
          <td className="ft-Wt-700">80 GM</td>
        </tr>
        <tr>
          <td>KAAPI BROWNIE</td>
          <td className="ft-Wt-700">100</td>
          <td className="ft-Wt-700">70 GM</td>
        </tr>
        <tr>
          <td>MUMBAI MASALA SANDWICH</td>
          <td className="ft-Wt-700">160</td>
          <td className="ft-Wt-700">180 GM</td>
        </tr>
        <tr>
          <td>COCOLATE MUFFINS</td>
          <td className="ft-Wt-700">120</td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>VANILA MUFFINS</td>
          <td className="ft-Wt-700">120</td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>CHOCOLATE CAKE</td>
          <td className="ft-Wt-700">160</td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>ALOO SAMOSA (2 NOS)</td>
          <td className="ft-Wt-700">100</td>
          <td className="ft-Wt-700"></td>
        </tr>

        <tr className="subHead">
          <td colSpan={3}>JAIN SNACKS</td>
        </tr>
        <tr>
          <td>SPINACH CHEESE CORN SANDWICH</td>
          <td className="ft-Wt-700">180</td>
          <td className="ft-Wt-700">200 GM</td>
        </tr>
        <tr>
          <td>JAIN SAMOSA (2 NOS)</td>
          <td className="ft-Wt-700">120</td>
          <td className="ft-Wt-700">100 GM</td>
        </tr>
        <tr>
          <td>JAIN SPRING ROLL (4 NOS)</td>
          <td className="ft-Wt-700">150</td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>JAIN CHEESE FINGER (4 NOS)</td>
          <td className="ft-Wt-700">150</td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>JAIN NUGGETS (5 NOS)</td>
          <td className="ft-Wt-700">150</td>
          <td className="ft-Wt-700"></td>
        </tr>

        <tr className="subHead">
          <td colSpan={3}>MERCHANDISE</td>
        </tr>
        <tr>
          <td>6 SECONDS MERCHANDISE</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>6 SECONDS FILTER COFFEE DECOCTION (pack of 1)</td>
          <td className="ft-Wt-700">75</td>
          <td className="ft-Wt-700">50 ML</td>
        </tr>

        <tr className="subHead">
          <td colSpan={3}>KAAPI POWDER</td>
        </tr>
        <tr>
          <td>CHICORY BLEND (80:20)</td>
          <td className="ft-Wt-700">150</td>
          <td className="ft-Wt-700">250 GM</td>
        </tr>
        <tr>
          <td>MYSORE KAAPI</td>
          <td className="ft-Wt-700">190</td>
          <td className="ft-Wt-700">250 GM</td>
        </tr>
        <tr>
          <td>PURE KAAPI</td>
          <td className="ft-Wt-700">200</td>
          <td className="ft-Wt-700">250 GM</td>
        </tr>

        <tr className="subHead">
          <td colSpan={3}>FILTERS</td>
        </tr>
        <tr>
          <td>S S KAAPI FILTER</td>
          <td className="ft-Wt-700">550</td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>S S KAAPI KETTLE</td>
          <td className="ft-Wt-700">800</td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>HAMMERED BRASS CUP N DABRA</td>
          <td className="ft-Wt-700">1555</td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>HAMMERED BRASS FILTER</td>
          <td className="ft-Wt-700">1830</td>
          <td className="ft-Wt-700"></td>
        </tr>

        <tr className="subHead">
          <td colSpan={3}>PACKED SNACKS</td>
        </tr>
        <tr>
          <td>KODUBALE</td>
          <td className="ft-Wt-700">120</td>
          <td className="ft-Wt-700">200 GMS</td>
        </tr>
        <tr>
          <td>BOMBAY MIXTURE</td>
          <td className="ft-Wt-700">120</td>
          <td className="ft-Wt-700">201 GMS</td>
        </tr>
        <tr>
          <td>COATED PEANUTS</td>
          <td className="ft-Wt-700">120</td>
          <td className="ft-Wt-700">202 GMS</td>
        </tr>
        <tr>
          <td>MOONG DAL</td>
          <td className="ft-Wt-700">120</td>
          <td className="ft-Wt-700">203 GMS</td>
        </tr>
        <tr>
          <td>KHARA BOONDHI</td>
          <td className="ft-Wt-700">120</td>
          <td className="ft-Wt-700">204 GMS</td>
        </tr>
        <tr>
          <td>MADDUR VADA (pack of 5)</td>
          <td className="ft-Wt-700">200</td>
          <td className="ft-Wt-700">205 GMS</td>
        </tr>

        <tr className="subHead">
          <td colSpan={3}>WESTERN BEVERAGES (250ml)</td>
        </tr>
        <tr>
          <td>CAPPUCCINO</td>
          <td className="ft-Wt-700">200</td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
        <tr>
          <td>LATTE</td>
          <td className="ft-Wt-700">180</td>
          <td className="ft-Wt-700">251 ML</td>
        </tr>
        <tr>
          <td>INDIANO</td>
          <td className="ft-Wt-700">150</td>
          <td className="ft-Wt-700">252 ML</td>
        </tr>
        <tr>
          <td>ESPRESSO</td>
          <td className="ft-Wt-700">100</td>
          <td className="ft-Wt-700">60ML</td>
        </tr>
      </table>
    );
  },
  cafe8: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>NESCO FOODS</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
          <td>GM/ML</td>
        </tr>
        <tr>
          <td>MASALA TEA</td>
          <td className="ft-Wt-700">50</td>
          <td className="ft-Wt-700">120 ML</td>
        </tr>
        <tr>
          <td>COFFEE</td>
          <td className="ft-Wt-700">50</td>
          <td className="ft-Wt-700">120 ML</td>
        </tr>
        <tr>
          <td>WATER</td>
          <td className="ft-Wt-700">20</td>
          <td className="ft-Wt-700">1000 ML</td>
        </tr>
        <tr>
          <td>SOFT DRINK - COKE</td>
          <td className="ft-Wt-700">70</td>
          <td className="ft-Wt-700">330 ML</td>
        </tr>
        <tr>
          <td>SOFT DRINK - THUMS UP</td>
          <td className="ft-Wt-700">70</td>
          <td className="ft-Wt-700">330 ML</td>
        </tr>
        <tr>
          <td>MAAZA</td>
          <td className="ft-Wt-700">20</td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
        <tr>
          <td>SPECIAL COFFEE</td>
          <td className="ft-Wt-700">160</td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
        <tr>
          <td>SANDWICHES</td>
          <td className="ft-Wt-700">180</td>
          <td className="ft-Wt-700">200 G</td>
        </tr>
        <tr>
          <td>GR. SANDWICHES</td>
          <td className="ft-Wt-700">220</td>
          <td className="ft-Wt-700">220 G</td>
        </tr>
        <tr>
          <td>VEG PUFF</td>
          <td className="ft-Wt-700">100</td>
          <td className="ft-Wt-700">75 G</td>
        </tr>
        <tr>
          <td>SAMOSA</td>
          <td className="ft-Wt-700">100</td>
          <td className="ft-Wt-700">2 NOS</td>
        </tr>
        <tr>
          <td>PEPPY PANEER PIZZA</td>
          <td className="ft-Wt-700">250</td>
          <td className="ft-Wt-700">350 GMS</td>
        </tr>
        <tr>
          <td>FARM HOUSE PIZZA</td>
          <td className="ft-Wt-700">250</td>
          <td className="ft-Wt-700">350 GMS</td>
        </tr>
        <tr>
          <td>BELGIUM CHOCOLATE PASTRY</td>
          <td className="ft-Wt-700">180</td>
          <td className="ft-Wt-700">100 GMS</td>
        </tr>
        <tr>
          <td>LOTUS BISCUST</td>
          <td className="ft-Wt-700">180</td>
          <td className="ft-Wt-700">100 GMS</td>
        </tr>
        <tr>
          <td>FALAFEL WRAP</td>
          <td className="ft-Wt-700">230</td>
          <td className="ft-Wt-700">200 GMS</td>
        </tr>
        <tr>
          <td>CHIPOTALE MAXICAN WRAP</td>
          <td className="ft-Wt-700">230</td>
          <td className="ft-Wt-700">200 GMS</td>
        </tr>
      </table>
    );
  },
  cafe9: () => {
    return (
      <>
        <table>
          <tr className="title">
            <td colSpan={3}>CHAAYOS</td>
          </tr>
          <tr className="header">
            <td>MENU</td>
            <td>PRICE</td>
            <td>GM/ML</td>
          </tr>
          <tr className="section">
            <td colSpan={3}>SNACKS</td>
          </tr>
          <tr>
            <td>VADA PAV</td>
            <td className="ft-Wt-700">170</td>
            <td className="ft-Wt-700">1PCS, 250GM</td>
          </tr>
          <tr>
            <td>BUN DABELI</td>
            <td className="ft-Wt-700">190</td>
            <td className="ft-Wt-700">1PCS, 230GM</td>
          </tr>
          <tr>
            <td>BUN SAMOSA</td>
            <td className="ft-Wt-700">170</td>
            <td className="ft-Wt-700">1PCS, 180GM</td>
          </tr>
          <tr className="section">
            <td colSpan={3}>MEALS</td>
          </tr>
          <tr>
            <td>KULHAD PAO BHAJI</td>
            <td className="ft-Wt-700">280</td>
            <td className="ft-Wt-700">350GM</td>
          </tr>
          <tr>
            <td>MATAR KULCHA</td>
            <td className="ft-Wt-700">280</td>
            <td className="ft-Wt-700">350GM</td>
          </tr>
          <tr>
            <td>CHILLI CHEESE TOAST</td>
            <td className="ft-Wt-700">250</td>
            <td className="ft-Wt-700">380GM</td>
          </tr>
          <tr className="section">
            <td colSpan={3}>TACOS</td>
          </tr>
          <tr>
            <td>VEG SWEET AND SPICY TACO</td>
            <td className="ft-Wt-700">270</td>
            <td className="ft-Wt-700">300GM</td>
          </tr>
          <tr>
            <td>VEG SHAMI KEBAB TACO</td>
            <td className="ft-Wt-700">270</td>
            <td className="ft-Wt-700">300GM</td>
          </tr>
          <tr className="section">
            <td colSpan={3}>ROLL</td>
          </tr>
          <tr>
            <td>PANEER TIKKA ROLL</td>
            <td className="ft-Wt-700">250</td>
            <td className="ft-Wt-700">380GM</td>
          </tr>
          <tr className="section">
            <td colSpan={3}>BEVERAGE</td>
          </tr>
          <tr>
            <td>DESI CHAI (100ML)</td>
            <td className="ft-Wt-700">60</td>
            <td className="ft-Wt-700">100ML</td>
          </tr>
          <tr>
            <td>DESI CHAI (200ML)</td>
            <td className="ft-Wt-700">120</td>
            <td className="ft-Wt-700">200ML</td>
          </tr>
          <tr>
            <td>LEMON ICED TEA</td>
            <td className="ft-Wt-700">240</td>
            <td className="ft-Wt-700">200ML</td>
          </tr>
          <tr>
            <td>PEACH ICED TEA</td>
            <td className="ft-Wt-700">240</td>
            <td className="ft-Wt-700">200ML</td>
          </tr>
          <tr>
            <td>COLD COFFEE</td>
            <td className="ft-Wt-700">280</td>
            <td className="ft-Wt-700">200ML</td>
            <tr class="spacer"></tr>
          </tr>
          <tr class="spacer"></tr>
        </table>
        <table>
          <tr className="title">
            <td colSpan={3}>SUBWAY</td>
          </tr>
          <tr className="header">
            <td>MENU</td>
            <td>PRICE</td>
            <td>GM/ML</td>
          </tr>
          <tr className="section">
            <td colSpan={3}>SANDWICHES</td>
          </tr>
          <tr>
            <td>VEGGIE DELITE</td>
            <td className="ft-Wt-700">250</td>
            <td className="ft-Wt-700">220 GM</td>
          </tr>
          <tr>
            <td>ALOO PATTY</td>
            <td className="ft-Wt-700">300</td>
            <td className="ft-Wt-700">275 GM</td>
          </tr>
          <tr>
            <td>PANEER ACHARI</td>
            <td className="ft-Wt-700">300</td>
            <td className="ft-Wt-700">240 GM</td>
          </tr>
          <tr>
            <td>CHEESE CHILLI</td>
            <td className="ft-Wt-700">300</td>
            <td className="ft-Wt-700">250 GM</td>
          </tr>
          <tr>
            <td>THE MEXICAN</td>
            <td className="ft-Wt-700">300</td>
            <td className="ft-Wt-700">250 GM</td>
          </tr>
          <tr className="section">
            <td colSpan={3}>WRAPS</td>
          </tr>
          <tr>
            <td>VEGGIE DELITE</td>
            <td className="ft-Wt-700">290</td>
            <td className="ft-Wt-700">334 GM</td>
          </tr>
          <tr>
            <td>PANEER TIKKA</td>
            <td className="ft-Wt-700">320</td>
            <td className="ft-Wt-700">350 GM</td>
          </tr>
          <tr>
            <td>ALOO PATTY</td>
            <td className="ft-Wt-700">320</td>
            <td className="ft-Wt-700">340 GM</td>
          </tr>
          <tr>
            <td>MEXICAN PATTY</td>
            <td className="ft-Wt-700">320</td>
            <td className="ft-Wt-700">350 GM</td>
          </tr>
          <tr className="section">
            <td colSpan={3}>SALADS</td>
          </tr>
          <tr>
            <td>VEGGIE DELITE</td>
            <td className="ft-Wt-700">290</td>
            <td className="ft-Wt-700">365 GM</td>
          </tr>
          <tr>
            <td>PANEER TIKKA</td>
            <td className="ft-Wt-700">280</td>
            <td className="ft-Wt-700">425 GM</td>
          </tr>
          <tr>
            <td>ALOO PATTY</td>
            <td className="ft-Wt-700">280</td>
            <td className="ft-Wt-700">420 GM</td>
          </tr>
          <tr>
            <td>MEXICAN PATTY</td>
            <td className="ft-Wt-700">280</td>
            <td className="ft-Wt-700">425 GM</td>
          </tr>
          <tr className="section">
            <td colSpan={3}>CAFÉ</td>
          </tr>
          <tr className="subsection">
            <td colSpan={3}>HOT BEVERAGES</td>
          </tr>
          <tr>
            <td>CAPPUCCINO</td>
            <td className="ft-Wt-700">180</td>
            <td className="ft-Wt-700">140 ML</td>
          </tr>
          <tr>
            <td>LATTE</td>
            <td className="ft-Wt-700">180</td>
            <td className="ft-Wt-700">140 ML</td>
          </tr>
          <tr>
            <td>ESPRESSO</td>
            <td className="ft-Wt-700">150</td>
            <td className="ft-Wt-700">30 ML / 60 ML</td>
          </tr>
          <tr>
            <td>AMERICANO</td>
            <td className="ft-Wt-700">150</td>
            <td className="ft-Wt-700">140 ML</td>
          </tr>
          <tr>
            <td>MALASA TEA</td>
            <td className="ft-Wt-700">100</td>
            <td className="ft-Wt-700">100 ML</td>
          </tr>
          <tr>
            <td>LEMON / GREEN TEA</td>
            <td className="ft-Wt-700">100</td>
            <td className="ft-Wt-700">100 ML</td>
          </tr>
          <tr className="subsection">
            <td colSpan={3}>COLD BEVERAGES</td>
          </tr>
          <tr>
            <td>CHOCOLATE MILKSHAKE</td>
            <td className="ft-Wt-700">250</td>
            <td className="ft-Wt-700">195 ML</td>
          </tr>
          <tr>
            <td>STRAWBERRY MILKSHAKE</td>
            <td className="ft-Wt-700">250</td>
            <td className="ft-Wt-700">195 ML</td>
          </tr>
          <tr>
            <td>RAW COCONUT WATER</td>
            <td className="ft-Wt-700">150</td>
            <td className="ft-Wt-700">200 ML</td>
          </tr>
          <tr>
            <td>RAW ALOE VERA LEMONADE</td>
            <td className="ft-Wt-700">150</td>
            <td className="ft-Wt-700">200 ML</td>
          </tr>
          <tr>
            <td>RAW ALPHONSO</td>
            <td className="ft-Wt-700">150</td>
            <td className="ft-Wt-700">175 ML</td>
          </tr>
          <tr>
            <td>RAW MIX FRUIT</td>
            <td className="ft-Wt-700">150</td>
            <td className="ft-Wt-700">175 ML</td>
          </tr>
          <tr className="section">
            <td colSpan={3}>COOKIES</td>
          </tr>
          <tr>
            <td>DOUBLE DARK CHUNK</td>
            <td className="ft-Wt-700">120</td>
            <td className="ft-Wt-700">40 GM</td>
          </tr>
        </table>
      </>
    );
  },
  cafe10: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>BLUE TOKAI</td>
        </tr>
        <tr className="header">
          <td>MENU</td>
          <td>PRICE</td>
          <td>GM/ML</td>
        </tr>
        <tr className="section">
          <td colSpan={3}>HOT COFFEE</td>
        </tr>
        <tr>
          <td>ESPRESSO</td>
          <td className="ft-Wt-700">180</td>
          <td className="ft-Wt-700">40 ML</td>
        </tr>
        <tr>
          <td>AMERICANO</td>
          <td className="ft-Wt-700">210</td>
          <td className="ft-Wt-700">170 ML</td>
        </tr>
        <tr>
          <td>CORTADO</td>
          <td className="ft-Wt-700">210</td>
          <td className="ft-Wt-700">100 ML</td>
        </tr>
        <tr>
          <td>FLAT WHITE</td>
          <td className="ft-Wt-700">250</td>
          <td className="ft-Wt-700">220 ML</td>
        </tr>
        <tr>
          <td>CAPPUCCINO</td>
          <td className="ft-Wt-700">250</td>
          <td className="ft-Wt-700">200 ML</td>
        </tr>
        <tr>
          <td>LATTE</td>
          <td className="ft-Wt-700">270</td>
          <td className="ft-Wt-700">270 ML</td>
        </tr>
        <tr>
          <td>HOT CHOCOLATE</td>
          <td className="ft-Wt-700">290</td>
          <td className="ft-Wt-700">200 ML</td>
        </tr>
        <tr className="section">
          <td colSpan={3}>COLD BREWS</td>
        </tr>
        <tr>
          <td>ICED ESPRESSO</td>
          <td className="ft-Wt-700">180</td>
          <td className="ft-Wt-700">100 ML</td>
        </tr>
        <tr>
          <td>ICED AMERICANO</td>
          <td className="ft-Wt-700">250</td>
          <td className="ft-Wt-700">215 ML</td>
        </tr>
        <tr>
          <td>ICED LATTE</td>
          <td className="ft-Wt-700">270</td>
          <td className="ft-Wt-700">215 ML</td>
        </tr>
        <tr>
          <td>VIETNAMESE STYLE ICED COFFEE</td>
          <td className="ft-Wt-700">300</td>
          <td className="ft-Wt-700">250 ML</td>
        </tr>
      </table>
    );
  },
  cafe11: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>
            KC ROASTER
          </td>
        </tr>
        <tr className="header">
          <td>MENU</td>
          <td>PRICE</td>
          <td>GM/ML</td>
        </tr>
        <tr className="section">
          <td colSpan={3}>SPECIALTY COFFEE</td>
        </tr>
        <tr>
          <td>ESPRESSO</td>
          <td className="ft-Wt-700">200</td>
          <td>30 ML</td>
        </tr>
        <tr>
          <td>LATTE</td>
          <td className="ft-Wt-700">240</td>
          <td>240 ML</td>
        </tr>
        <tr>
          <td>AMERICANO</td>
          <td className="ft-Wt-700">240</td>
          <td>240 ML</td>
        </tr>
        <tr>
          <td>CAPPUCCINO</td>
          <td className="ft-Wt-700">240</td>
          <td>240 ML</td>
        </tr>
        <tr>
          <td>FLAT WHITE</td>
          <td className="ft-Wt-700">240</td>
          <td>240 ML</td>
        </tr>
        <tr className="section">
          <td colSpan={3}>ARTISANAL COFFEE</td>
        </tr>
        <tr>
          <td>SPICED LATTE</td>
          <td className="ft-Wt-700">260</td>
          <td>240 ML</td>
        </tr>
        <tr>
          <td>OKO CARIBE MOCHA</td>
          <td className="ft-Wt-700">280</td>
          <td>240 ML</td>
        </tr>
        <tr>
          <td>HAZELNUT PRALINE MOCHA</td>
          <td className="ft-Wt-700">300</td>
          <td>240 ML</td>
        </tr>
        <tr className="section">
          <td colSpan={3}>COLDBREW LATTES</td>
        </tr>
        <tr>
          <td>COLDBREW LATTE (UNSWEETENED)</td>
          <td className="ft-Wt-700">240</td>
          <td>240 ML</td>
        </tr>
        <tr>
          <td>VIETNAMESE COLDBREW</td>
          <td className="ft-Wt-700">260</td>
          <td>240 ML</td>
        </tr>
        <tr>
          <td>OKO CARIBE COLDBREW</td>
          <td className="ft-Wt-700">280</td>
          <td>240 ML</td>
        </tr>
        <tr>
          <td>HAZELNUT PRALINE SHAKERATO</td>
          <td className="ft-Wt-700">300</td>
          <td>240 ML</td>
        </tr>
        <tr>
          <td>ALMOND DATE COLDBREW (VEGAN)</td>
          <td className="ft-Wt-700">300</td>
          <td>240 ML</td>
        </tr>
        <tr className="section">
          <td colSpan={3}>COLDBREW</td>
        </tr>
        <tr>
          <td>KCROASTERS COLDBREW (ORIGINAL/CINNAMON)</td>
          <td className="ft-Wt-700">200</td>
          <td>240 ML</td>
        </tr>
        <tr>
          <td>COLD FASHIONED</td>
          <td className="ft-Wt-700">260</td>
          <td>240 ML</td>
        </tr>
        <tr>
          <td>LIMONCELLO COLDBREW</td>
          <td className="ft-Wt-700">280</td>
          <td>240 ML</td>
        </tr>
        <tr className="section">
          <td colSpan={3}>MILK OPTIONS</td>
        </tr>
        <tr>
          <td>SOY MILK</td>
          <td className="ft-Wt-700">80</td>
          <td>240 ML</td>
        </tr>
        <tr>
          <td>OAT MILK</td>
          <td className="ft-Wt-700">80</td>
          <td>240 ML</td>
        </tr>
        <tr className="section">
          <td colSpan={3}>FOOD MENU</td>
        </tr>
        <tr>
          <td>ALMOND CROISSANT</td>
          <td className="ft-Wt-700">250</td>
          <td>200 GMS</td>
        </tr>
        <tr>
          <td>JALAPENO CREAM CHEESE CROISSANT</td>
          <td className="ft-Wt-700">300</td>
          <td>250 GMS</td>
        </tr>
        <tr>
          <td>KOREAN GARLIC BREAD</td>
          <td className="ft-Wt-700">330</td>
          <td>250 GMS</td>
        </tr>
        <tr>
          <td>PIZZA DANISH</td>
          <td className="ft-Wt-700">310</td>
          <td>250 GMS</td>
        </tr>
        <tr>
          <td>DARK CHOCOLATE BABKA</td>
          <td className="ft-Wt-700">295</td>
          <td>250 GMS</td>
        </tr>
        <tr>
          <td>BANANA BREAD</td>
          <td className="ft-Wt-700">100</td>
          <td>150g</td>
        </tr>
        <tr>
          <td>CARROT CAKE</td>
          <td className="ft-Wt-700">260</td>
          <td>150g</td>
        </tr>
        <tr>
          <td>FRENCH CHOCOLATE</td>
          <td className="ft-Wt-700">260</td>
          <td>150g</td>
        </tr>
      </table>
    );
  },
  cafe12: () => {
    return (
      <table>
        <tr className="title" >
          <td colSpan={3}>
            TEA POST
          </td>
        </tr>
        <tr className="header">
          <td>MENU</td>
          <td>PRICE</td>
          <td>GM/ML</td>
        </tr>
        <tr className="section">
          <td colSpan={3}>TEA</td>
        </tr>
        <tr>
          <td>MASALA TEA</td>
          <td className="ft-Wt-700">60</td>
          <td className="ft-Wt-700">120 ML</td>
        </tr>
        <tr>
          <td>GINGER TEA</td>
          <td className="ft-Wt-700">80</td>
          <td className="ft-Wt-700">120 ML</td>
        </tr>
        <tr>
          <td>ELAICHI TEA</td>
          <td className="ft-Wt-700">80</td>
          <td className="ft-Wt-700">120 ML</td>
        </tr>
        <tr>
          <td>GUD WALI CHAI</td>
          <td className="ft-Wt-700">80</td>
          <td className="ft-Wt-700">120 ML</td>
        </tr>
        <tr>
          <td>KULHAD CHAI</td>
          <td className="ft-Wt-700">100</td>
          <td className="ft-Wt-700">120 ML</td>
        </tr>
        <tr>
          <td>SAFFRON TEA</td>
          <td className="ft-Wt-700">120</td>
          <td className="ft-Wt-700">120 ML</td>
        </tr>
        <tr className="section">
          <td colSpan={3}>HOT BOURNVITA</td>
        </tr>
        <tr>
          <td>HOT BOURNVITA</td>
          <td className="ft-Wt-700">120</td>
          <td className="ft-Wt-700">180 ML</td>
        </tr>
        <tr className="section">
          <td colSpan={3}>COFFEE</td>
        </tr>
        <tr>
          <td>HOT COFFEE</td>
          <td className="ft-Wt-700">60</td>
          <td className="ft-Wt-700">120 ML</td>
        </tr>
        <tr>
          <td>BLACK COFFEE</td>
          <td className="ft-Wt-700">60</td>
          <td className="ft-Wt-700">120 ML</td>
        </tr>
        <tr>
          <td>COLD COFFEE</td>
          <td className="ft-Wt-700">150</td>
          <td className="ft-Wt-700">300 ML</td>
        </tr>
        <tr className="section">
          <td colSpan={3}>ICE TEA</td>
        </tr>
        <tr>
          <td>LEMON ICE TEA</td>
          <td className="ft-Wt-700">150</td>
          <td className="ft-Wt-700">300 ML</td>
        </tr>
        <tr>
          <td>PEACH ICE TEA</td>
          <td className="ft-Wt-700">150</td>
          <td className="ft-Wt-700">300 ML</td>
        </tr>
        <tr className="section">
          <td colSpan={3}>TEA WITHOUT MILK</td>
        </tr>
        <tr>
          <td>GREEN TEA</td>
          <td className="ft-Wt-700">60</td>
          <td className="ft-Wt-700">180 ML</td>
        </tr>
        <tr>
          <td>BLACK TEA</td>
          <td className="ft-Wt-700">60</td>
          <td className="ft-Wt-700">180 ML</td>
        </tr>
        <tr className="section">
          <td colSpan={3}>NASHTA</td>
        </tr>
        <tr>
          <td>VEG PUFF</td>
          <td className="ft-Wt-700">60</td>
          <td className="ft-Wt-700">100 GMS</td>
        </tr>
        <tr>
          <td>THEPLA (J)</td>
          <td className="ft-Wt-700">100</td>
          <td className="ft-Wt-700">80 GMS</td>
        </tr>
        <tr>
          <td>MASKA BUN (J)</td>
          <td className="ft-Wt-700">80</td>
          <td className="ft-Wt-700">120 GMS</td>
        </tr>
      </table>
    );
  },
  cafe13: () => {
    return (
      <>
        <table>
          <tr className="title">
            <td colSpan={3}>TIBBS</td>
          </tr>
          <tr className="head">
            <td>MENU</td>
            <td>PRICE</td>
            <td>GM/ML</td>
          </tr>
          <tr className="spacer">
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>VEG COMBO 1 (FRNK + BEVERAGE)</td>
            <td className="ft-Wt-700">200</td>
            <td className="ft-Wt-700">200 GMS + 250 ML</td>
          </tr>
          <tr>
            <td>BEVERAGE + BROWNIE</td>
            <td className="ft-Wt-700">150</td>
            <td className="ft-Wt-700">250 ML + 80 GMS</td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>FRANKIE</td>
          </tr>
          <tr>
            <td>VEG FRANKIE</td>
            <td className="ft-Wt-700">160</td>
            <td className="ft-Wt-700">200 GMS</td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>BIRYANIS</td>
          </tr>
          <tr>
            <td>VEG BIRYANI</td>
            <td className="ft-Wt-700">230</td>
            <td className="ft-Wt-700">600 GMS</td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>VEG FRANKIE</td>
          </tr>
          <tr>
            <td>CLASSIC VEG</td>
            <td className="ft-Wt-700">160</td>
            <td className="ft-Wt-700">200 GMS</td>
          </tr>
          <tr>
            <td>VEG SEZ</td>
            <td className="ft-Wt-700">160</td>
            <td className="ft-Wt-700">200 GMS</td>
          </tr>
          <tr>
            <td>VEG CHIPOTLE</td>
            <td className="ft-Wt-700">160</td>
            <td className="ft-Wt-700">200 GMS</td>
          </tr>
          <tr>
            <td>VEG CHEESE JALAPENO</td>
            <td className="ft-Wt-700">160</td>
            <td className="ft-Wt-700">200 GMS</td>
          </tr>
          <tr>
            <td>TAWA PANEER</td>
            <td className="ft-Wt-700">160</td>
            <td className="ft-Wt-700">200 GMS</td>
          </tr>
          <tr>
            <td>PANEER CHILLY</td>
            <td className="ft-Wt-700">160</td>
            <td className="ft-Wt-700">200 GMS</td>
          </tr>
          <tr>
            <td>EXTRA CHEESE</td>
            <td className="ft-Wt-700">50</td>
            <td className="ft-Wt-700">20 GMS</td>
          </tr>
          <tr>
            <td>CHIPOTLE SAUCE</td>
            <td className="ft-Wt-700">50</td>
            <td className="ft-Wt-700">50 GMS</td>
          </tr>
          <tr>
            <td>CHEESE JALAPENO SAUCE</td>
            <td className="ft-Wt-700">50</td>
            <td className="ft-Wt-700">50 GMS</td>
          </tr>
          <tr>
            <td>SHIKANJI</td>
            <td className="ft-Wt-700">50</td>
            <td className="ft-Wt-700">250 ML</td>
          </tr>
          <tr>
            <td>BROWNIE</td>
            <td className="ft-Wt-700">100</td>
            <td className="ft-Wt-700">80 GMS</td>
          </tr>
          <tr>
            <td>NACHOS</td>
            <td className="ft-Wt-700">100</td>
            <td className="ft-Wt-700">75 GMS</td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>PROTIROTI</td>
          </tr>
          <tr>
            <td>CHETTINAD SUKHA SOYA CHAAP</td>
            <td className="ft-Wt-700">200</td>
            <td className="ft-Wt-700">200 GMS</td>
          </tr>
          <tr>
            <td>BHUNA SUKHA SOYA CHAAP</td>
            <td className="ft-Wt-700">200</td>
            <td className="ft-Wt-700">200 GMS</td>
          </tr>
          <tr>
            <td>TANDOORI SOYA TIKKA CHAAP</td>
            <td className="ft-Wt-700">200</td>
            <td className="ft-Wt-700">200 GMS</td>
          </tr>
          <tr class="spacer"></tr>
        </table>

        <table>
          <tr className="title">
            <td colSpan={3}>SOCIETY TEA TERMINAL</td>
          </tr>
          <tr className="head">
            <td>MENU</td>
            <td>PRICE</td>
            <td>GM/ML</td>
          </tr>
          <tr className="spacer">
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>REGULAR TEA</td>
            <td className="ft-Wt-700">50</td>
            <td className="ft-Wt-700">100 ML</td>
          </tr>
          <tr>
            <td>MASALA TEA</td>
            <td className="ft-Wt-700">60</td>
            <td className="ft-Wt-700">100 ML</td>
          </tr>
          <tr>
            <td>ELAICHI TEA</td>
            <td className="ft-Wt-700">60</td>
            <td className="ft-Wt-700">100 ML</td>
          </tr>
          <tr>
            <td>ICED TEA</td>
            <td className="ft-Wt-700">80</td>
            <td className="ft-Wt-700">220 ML</td>
          </tr>
          <tr>
            <td>CAPPUCCINO</td>
            <td className="ft-Wt-700">60</td>
            <td className="ft-Wt-700">100 ML</td>
          </tr>
          <tr>
            <td>COLD COFFEE</td>
            <td className="ft-Wt-700">100</td>
            <td className="ft-Wt-700">200 ML</td>
          </tr>
          <tr>
            <td>KHARI</td>
            <td className="ft-Wt-700">40</td>
            <td className="ft-Wt-700">2 PCS</td>
          </tr>
          <tr>
            <td>TOAST</td>
            <td className="ft-Wt-700">40</td>
            <td className="ft-Wt-700">2 PCS</td>
          </tr>
          <tr>
            <td>BUN MASKA JAM (1 PC)</td>
            <td className="ft-Wt-700">60</td>
            <td className="ft-Wt-700">1 PC</td>
          </tr>
          <tr>
            <td>GREEN TEA BAGS</td>
            <td className="ft-Wt-700">60</td>
            <td className="ft-Wt-700">150 ML</td>
          </tr>
          <tr>
            <td>CLEANSE</td>
            <td className="ft-Wt-700">60</td>
            <td className="ft-Wt-700">100 ML</td>
          </tr>
          <tr>
            <td>HALDI DOODH</td>
            <td className="ft-Wt-700">60</td>
            <td className="ft-Wt-700">100 ML</td>
          </tr>
          <tr>
            <td>POHA</td>
            <td className="ft-Wt-700">70</td>
            <td className="ft-Wt-700">100 GMS</td>
          </tr>
          <tr>
            <td>UPMA</td>
            <td className="ft-Wt-700">70</td>
            <td className="ft-Wt-700">100 GMS</td>
          </tr>
          <tr className="spacer">
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>RTD ICED TEA</td>
          </tr>
          <tr>
            <td>PEACH &amp; APRICOT</td>
            <td className="ft-Wt-700">60</td>
            <td className="ft-Wt-700">250 ML</td>
          </tr>
          <tr>
            <td>LEMON</td>
            <td className="ft-Wt-700">60</td>
            <td className="ft-Wt-700">250 ML</td>
          </tr>
          <tr>
            <td>GINGER CINNAMON BURGAMOT</td>
            <td className="ft-Wt-700">60</td>
            <td className="ft-Wt-700">250 ML</td>
          </tr>
          <tr>
            <td>CAMAMILE JASMINE</td>
            <td className="ft-Wt-700">60</td>
            <td className="ft-Wt-700">250 ML</td>
          </tr>
          <tr>
            <td>2 BTL ANY FLAVOR</td>
            <td className="ft-Wt-700">100</td>
            <td className="ft-Wt-700">250 ML (QTY 2)</td>
          </tr>
          <tr>
            <td>4 BTL ANY FLAVOR</td>
            <td className="ft-Wt-700">200</td>
            <td className="ft-Wt-700">250 ML (QTY 4)</td>
          </tr>
          <tr className="spacer">
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>DETOX SERIES</td>
          </tr>
          <tr>
            <td>GREEN TEA</td>
            <td className="ft-Wt-700">60</td>
            <td className="ft-Wt-700">150 ML</td>
          </tr>
          <tr>
            <td>KASHMIRI KAHWA</td>
            <td className="ft-Wt-700">60</td>
            <td className="ft-Wt-700">150 ML</td>
          </tr>
          <tr>
            <td>TULSI RITUAL</td>
            <td className="ft-Wt-700">60</td>
            <td className="ft-Wt-700">150 ML</td>
          </tr>
          <tr>
            <td>MORROCCON MINT</td>
            <td className="ft-Wt-700">60</td>
            <td className="ft-Wt-700">150 ML</td>
          </tr>
          <tr>
            <td>FLOWERY BOUQUET</td>
            <td className="ft-Wt-700">60</td>
            <td className="ft-Wt-700">150 ML</td>
          </tr>
          <tr>
            <td>SOOTHING CAMOMILE</td>
            <td className="ft-Wt-700">60</td>
            <td className="ft-Wt-700">150 ML</td>
          </tr>
          <tr>
            <td>DIVINE DARJEELING</td>
            <td className="ft-Wt-700">60</td>
            <td className="ft-Wt-700">150 ML</td>
          </tr>
        </table>
      </>
    );
  },
  cafe14: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>DAILY DELI (NESCO FOOD)</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
          <td>GM/ML</td>
        </tr>
        <tr className="spacer">
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>MASALA TEA</td>
          <td className="ft-Wt-700">50</td>
          <td className="ft-Wt-700">120 ML</td>
        </tr>
        <tr>
          <td>COFFEE</td>
          <td className="ft-Wt-700">50</td>
          <td className="ft-Wt-700">120 ML</td>
        </tr>
        <tr>
          <td>WATER</td>
          <td className="ft-Wt-700">20</td>
          <td className="ft-Wt-700">1 LTR</td>
        </tr>
        <tr>
          <td>SOFT DRINK</td>
          <td className="ft-Wt-700">70</td>
          <td className="ft-Wt-700">330 ML</td>
        </tr>
        <tr>
          <td>SANDWICHES</td>
          <td className="ft-Wt-700">180</td>
          <td className="ft-Wt-700">200 GMS</td>
        </tr>
        <tr>
          <td>GRILLED SANDWICHES</td>
          <td className="ft-Wt-700">220</td>
          <td className="ft-Wt-700">220 GMS</td>
        </tr>
        <tr>
          <td>FARM HOUSE PIZZA</td>
          <td className="ft-Wt-700">250</td>
          <td className="ft-Wt-700">350 GMS</td>
        </tr>
        <tr>
          <td>PEPPY PANEER PIZZA</td>
          <td className="ft-Wt-700">250</td>
          <td className="ft-Wt-700">350 GMS</td>
        </tr>
        <tr>
          <td>BELGIUM CHOCOLATE PASTRY</td>
          <td className="ft-Wt-700">180</td>
          <td className="ft-Wt-700">100 GMS</td>
        </tr>
        <tr>
          <td>LOTUS BISCOP CHEESE CAKE</td>
          <td className="ft-Wt-700">180</td>
          <td className="ft-Wt-700">100 GMS</td>
        </tr>
        <tr>
          <td>FALAFEL WRAP</td>
          <td className="ft-Wt-700">230</td>
          <td className="ft-Wt-700">200 GMS</td>
        </tr>
        <tr>
          <td>CHIPTOLE MEXICAN WRAP</td>
          <td className="ft-Wt-700">230</td>
          <td className="ft-Wt-700">200 GMS</td>
        </tr>
      </table>
    );
  },
  cafe15: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>ZUO - SNACKS</td>
        </tr>

        <tr className="head">
          <td>Menu</td>
          <td> MRP </td>
        </tr>

        <tr className="subHead">
          <td colSpan={3}>BEVERAGES</td>
        </tr>
        <tr>
          <td>SOFT DRINK (330ML)</td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>
        <tr>
          <td>BISLERI MINERAL WATER</td>
          <td className="ft-Wt-700"> 30.00 </td>
        </tr>
        <tr>
          <td>JASMIN TEA</td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>

        <tr className="subHead">
          <td colSpan={3}>MAINS</td>
        </tr>
        <tr>
          <td>FRAGRAENT FRIED RICE</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>BURNT GARLIC FRIED RICE</td>
          <td className="ft-Wt-700"> 220.00 </td>
        </tr>
        <tr>
          <td>SHANGHAI HAKKA NOODLES / FRIED RICE</td>
          <td className="ft-Wt-700"> 220.00 </td>
        </tr>
        <tr>
          <td>HAKKA NOODLES</td>
          <td className="ft-Wt-700"> 250.00 </td>
        </tr>

        <tr className="subHead">
          <td colSpan={3}>APPETIZER</td>
        </tr>
        <tr>
          <td>DRY CHILLI COTTAGE CHEESE (PANEER)</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>CRISPY SPRING ROLL (2 ROLLS)</td>
          <td className="ft-Wt-700"> 150.00</td>
        </tr>
        <tr className="title">
          <td colSpan={3}>Quench &amp; QuickBites</td>
        </tr>

        <tr className="head">
          <td>PARTICULAR</td>
          <td> PRICES </td>
        </tr>
        <tr>
          <td>MASALA TEA</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>COFFEE</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>WATER</td>
          <td className="ft-Wt-700"> 20.00 </td>
        </tr>
        <tr>
          <td>SOFT DRINK</td>
          <td className="ft-Wt-700"> 20.00 </td>
        </tr>
        <tr>
          <td>NATURE DAY</td>
          <td className="ft-Wt-700"> 30.00 </td>
        </tr>
        <tr>
          <td>SPECIAL COFFEE</td>
          <td className="ft-Wt-700"> 160.00 </td>
        </tr>
        <tr>
          <td>SANDWICHES</td>
          <td className="ft-Wt-700"> 180.00 </td>
        </tr>
        <tr>
          <td>GR. SANDWICHES</td>
          <td className="ft-Wt-700"> 220.00 </td>
        </tr>
        <tr>
          <td>STROMBOLI PANEER</td>
          <td className="ft-Wt-700"> 90.00 </td>
        </tr>
        <tr>
          <td>CROISSANT PESTO CHEESE</td>
          <td className="ft-Wt-700"> 90.00 </td>
        </tr>
        <tr>
          <td>CROISSANT CHOCOLATE</td>
          <td className="ft-Wt-700"> 90.00 </td>
        </tr>
        <tr>
          <td>VEG PUFF</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>SAMOSA</td>
          <td className="ft-Wt-700"> 80.00</td>
        </tr>
      </table>
    );
  },
  cafe16: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>NORTH PAVILLION</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
          <td>GM/ML</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>BIRYANIS</td>
        </tr>
        <tr>
          <td>VEG BIRYANI WITH RAITA + SALAD</td>
          <td className="ft-Wt-700">220</td>
          <td className="ft-Wt-700">250 GMS-300 GMS, VEG BIRYANI, RAITA 150 GMS</td>
        </tr>

        <tr className="subHead">
          <td colSpan={3}>ROLLS</td>
        </tr>
        <tr>
          <td>PANEER BUTTER MASALA ROLL</td>
          <td className="ft-Wt-700">250</td>
          <td className="ft-Wt-700">180 GMS - 200 GMS</td>
        </tr>
        <tr>
          <td>SOYA CHAAP MAKHANI ROLL</td>
          <td className="ft-Wt-700">220</td>
          <td className="ft-Wt-700">180 GMS - 200 GMS</td>
        </tr>
        <tr>
          <td>ALOO CHEESE ROLL</td>
          <td className="ft-Wt-700">200</td>
          <td className="ft-Wt-700">180 GMS - 200 GMS</td>
        </tr>
        <tr>
          <td>PANEER TIKKA ROLL</td>
          <td className="ft-Wt-700">220</td>
          <td className="ft-Wt-700">180 GMS - 200 GMS</td>
        </tr>
        <tr>
          <td>SAMOSA (2 PCS)</td>
          <td className="ft-Wt-700">120</td>
          <td className="ft-Wt-700">120 GMS</td>
        </tr>

        <tr className="subHead">
          <td colSpan={3}>BEVERAGES AND SWEET</td>
        </tr>
        <tr>
          <td>1 PLATE GULAB JAMUN (2 PC)</td>
          <td className="ft-Wt-700">60</td>
          <td className="ft-Wt-700">50 GMS PER PIECE</td>
        </tr>
        <tr>
          <td>MASALA CHAAS (300 ML)</td>
          <td className="ft-Wt-700">75</td>
          <td className="ft-Wt-700">300 ML</td>
        </tr>
      </table>
    );
  },
  db3: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>ANANDO</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>SPECIAL MASALA MILK</td>
          <td className="ft-Wt-700"> 130.00 </td>
        </tr>
        <tr>
          <td>BELGIAN CHOCOLATE</td>
          <td className="ft-Wt-700"> 135.00 </td>
        </tr>
        <tr>
          <td>TROPICAL COCONUT</td>
          <td className="ft-Wt-700"> 130.00 </td>
        </tr>
        <tr>
          <td>SPLENDID STRAWBERRY</td>
          <td className="ft-Wt-700"> 130.00 </td>
        </tr>
        <tr>
          <td>TANGO WITH MANGO</td>
          <td className="ft-Wt-700"> 130.00 </td>
        </tr>
        <tr>
          <td>SINFUL SITAFAL</td>
          <td className="ft-Wt-700"> 130.00 </td>
        </tr>
        <tr>
          <td>CHOCO ALMOND CRUNCHY</td>
          <td className="ft-Wt-700"> 130.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>MAHARAJA BHOG ( 5 Dish Menu)</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>EXECUTIVE BOX</td>
          <td className="ft-Wt-700"> 300.00 </td>
        </tr>
        <tr>
          <td>PURI-5 PCS WITH BHAJI, ACHAR,SHRIKHAND</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>DAL BATTI CHURMA</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>VADA PAV</td>
          <td className="ft-Wt-700"> 40.00 </td>
        </tr>
        <tr>
          <td>SAMOSA PAV </td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>MASALA CHASS</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>RING DHOKLA WITH COCONUT CHUTNEY</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>TEA</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>VEG BIRYANI WITH RAITA, PAPAD &amp; GULAB JAMUN</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>THEPLA , BHAJI &amp; GULAB JAMUN</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>PAV BHAJI</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>EXPRESS BOX</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>CHETTINAD EXPRESS</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>IDLI (J)</td>
          <td className="ft-Wt-700"> 80.00 </td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>DOSA</td>
        </tr>
        <tr>
          <td>DOSA : SADA (J)</td>
          <td className="ft-Wt-700"> 90.00 </td>
        </tr>
        <tr>
          <td>DOSA : MASALA </td>
          <td className="ft-Wt-700"> 120.00 </td>
        </tr>
        <tr>
          <td>DOSA : MYSORE MASALA</td>
          <td className="ft-Wt-700"> 150.00 </td>
        </tr>
        <tr>
          <td>DOSA : MYSORE SADA </td>
          <td className="ft-Wt-700"> 130.00 </td>
        </tr>
        <tr>
          <td>DOSA : BUTTER CHEESE MASALA</td>
          <td className="ft-Wt-700"> 160.00 </td>
        </tr>
        <tr>
          <td>DOSA : BUTTER CHEESE (J)</td>
          <td className="ft-Wt-700"> 140.00 </td>
        </tr>
        <tr>
          <td>DOSA : PAV BHAJI (J)</td>
          <td className="ft-Wt-700"> 130.00 </td>
        </tr>
        <tr>
          <td>UTTAPPAM : ONION / TOMATO</td>
          <td className="ft-Wt-700"> 120.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>QUENCH</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>COOL</td>
        </tr>
        <tr>
          <td>WATER (1 LIT) </td>
          <td className="ft-Wt-700"> 20.00 </td>
        </tr>
        <tr>
          <td>WATER (200 ML) - 1 CASE </td>
          <td className="ft-Wt-700"> 288.00 </td>
        </tr>
        <tr>
          <td>SOFT BEVERAGES</td>
          <td> MRP </td>
        </tr>
        <tr>
          <td>MINUTE MAID</td>
          <td> MRP </td>
        </tr>
        <tr>
          <td>NATURE DAY</td>
          <td> MRP </td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>WARM</td>
        </tr>
        <tr>
          <td>TEA</td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>
        <tr>
          <td>COFFEE</td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>R.S.MANI </td>
        </tr>
        <tr className="head">
          <td>NAME</td>
          <td> PRICE </td>
        </tr>
        <tr>
          <td> R S MANI FILTER COFFEE </td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>
        <tr>
          <td> ADRAK CHAI </td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>
        <tr>
          <td> IDLI </td>
          <td className="ft-Wt-700"> 80.00 </td>
        </tr>
        <tr>
          <td> MEDU VADA </td>
          <td className="ft-Wt-700"> 100.00 </td>
        </tr>
        <tr>
          <td> PINEAPPLE SHEERA </td>
          <td className="ft-Wt-700"> 120.00 </td>
        </tr>
        <tr>
          <td> DOSAS </td>
          <td className="ft-Wt-700"> 140.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>QUICK BITES</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td> PRICE </td>
        </tr>
        <tr>
          <td>CHOLE KULCHA (J)</td>
          <td className="ft-Wt-700"> 150.00 </td>
        </tr>
        <tr>
          <td>CHOLE BHATURE (J)</td>
          <td className="ft-Wt-700"> 180.00 </td>
        </tr>
        <tr>
          <td>PAV BHAJI (J)</td>
          <td className="ft-Wt-700"> 150.00 </td>
        </tr>
        <tr>
          <td>SAMOSA CHOLE (J)</td>
          <td className="ft-Wt-700"> 90.00 </td>
        </tr>
        <tr>
          <td>MISAL PAV (J)</td>
          <td className="ft-Wt-700"> 90.00 </td>
        </tr>
        <tr>
          <td>VADA PAV (J)</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>SAMOSA (J)</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>EXTRA PAV - (1PC)</td>
          <td className="ft-Wt-700"> 10.00 </td>
        </tr>
        <tr>
          <td>EXTRA KULCHA / BHATURA - (1PC)</td>
          <td className="ft-Wt-700"> 20.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>INDIC</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td> PRICE </td>
        </tr>
        <tr>
          <td>URBAN THALI. (J)</td>
          <td className="ft-Wt-700"> 270.00 </td>
        </tr>
        <tr>
          <td>BIRYANI : PANEER MAKHANI (J)</td>
          <td className="ft-Wt-700"> 260.00 </td>
        </tr>
        <tr>
          <td>BIRYANI : SUBZ (J)</td>
          <td className="ft-Wt-700"> 250.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>DAILY DELI</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>SANDWICH</td>
        </tr>
        <tr>
          <td>GRILLED VEG SANDWICH (J)</td>
          <td className="ft-Wt-700"> 160.00 </td>
        </tr>
        <tr>
          <td>GRILLED VEG SANDWICH WITH CHEESE (J)</td>
          <td className="ft-Wt-700"> 180.00 </td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>WRAPS</td>
        </tr>
        <tr>
          <td>VEGETABLE WRAPS (J)</td>
          <td className="ft-Wt-700"> 120.00 </td>
        </tr>
        <tr>
          <td>CHEESE &amp; VEGETABLE WRAPS (J)</td>
          <td className="ft-Wt-700"> 140.00 </td>
        </tr>
        <tr>
          <td>FRENCH FRIES</td>
          <td className="ft-Wt-700"> 90.00 </td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>PIZZA </td>
        </tr>
        <tr>
          <td>CHEESE MARGHERITA</td>
          <td className="ft-Wt-700"> 260.00 </td>
        </tr>
        <tr>
          <td>VEGGIE DELIGHT </td>
          <td className="ft-Wt-700"> 270.00 </td>
        </tr>
        <tr>
          <td>PEPPY PANEER </td>
          <td className="ft-Wt-700"> 280.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>CHINA TIMES</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td> PRICE </td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}> CHINESE COMBOS </td>
        </tr>
        <tr>
          <td> FRIED RICE WITH MANCHURIAN (J) </td>
          <td className="ft-Wt-700"> 260.00 </td>
        </tr>
        <tr>
          <td> NOODLES WITH MANCHURIAN (J) </td>
          <td className="ft-Wt-700"> 260.00 </td>
        </tr>
        <tr>
          <td> VEG FRIED RICE </td>
          <td className="ft-Wt-700"> 180.00 </td>
        </tr>
        <tr>
          <td> VEG NOODLES </td>
          <td className="ft-Wt-700"> 180.00</td>
        </tr>
      </table>
    );
  },
  openBay: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>SHIV SAGAR</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
          <td></td>
        </tr>
        <tr>
          <td>PAV BHAJI</td>
          <td className="ft-Wt-700"> 200.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr className="title">
          <td colSpan={3}>INDIC THALI</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
          <td></td>
        </tr>
        <tr>
          <td>URBAN THALI- VEG</td>
          <td className="ft-Wt-700"> 270.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr className="title">
          <td colSpan={3}>R.S.MANI </td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
          <td></td>
        </tr>
        <tr>
          <td> DOSA SADA </td>
          <td className="ft-Wt-700"> 100.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td> DOSA MASALA </td>
          <td className="ft-Wt-700"> 120.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td> BUTTER SADA DOSA </td>
          <td className="ft-Wt-700"> 120.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td> GHEE ROAST </td>
          <td className="ft-Wt-700"> 140.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td> MYSORE SADA DOSA </td>
          <td className="ft-Wt-700"> 120.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td> MYSORE MASALA DOSA </td>
          <td className="ft-Wt-700"> 140.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td> IDLI WADA PLATE</td>
          <td className="ft-Wt-700"> 100.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr className="title">
          <td colSpan={3}>CHINA TIME</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
          <td></td>
        </tr>
        <tr>
          <td>MEAL - VEG FRIED RICE</td>
          <td className="ft-Wt-700"> 180.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>Paneer Veg Chilly in Basil Sauce</td>
          <td className="ft-Wt-700"> 180.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>Veg Combo</td>
          <td className="ft-Wt-700"> 260.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr className="title">
          <td colSpan={3}>QUICK BITES</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
          <td></td>
        </tr>
        <tr>
          <td>CHOLE KULCHA</td>
          <td className="ft-Wt-700"> 150.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr className="title">
          <td colSpan={3}>TIBBS</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
          <td></td>
        </tr>
        <tr>
          <td>VEG FRANKIE</td>
          <td className="ft-Wt-700"> 160.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>TAWA PANEER</td>
          <td className="ft-Wt-700"> 160.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr className="title">
          <td colSpan={3}>MAHARAJA BHOG</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
          <td></td>
        </tr>
        <tr>
          <td>VADA PAV </td>
          <td className="ft-Wt-700"> 40.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>SAMOSA PAV </td>
          <td className="ft-Wt-700"> 60.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr className="title">
          <td colSpan={3}>RAJDHANI THALI</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
          <td></td>
        </tr>
        <tr>
          <td>RAJDHANI EXECUTIVE THALI</td>
          <td className="ft-Wt-700"> 280.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>Samosa - 2 Pcs</td>
          <td className="ft-Wt-700"> 60 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>(Samosa 2 Pcs + Chutney + Fried Chilli)</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Khaman Dhokla </td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>(Dhokla 5 Pcs + Chutney + Fried Chilli)</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Samosa Chole Chat</td>
          <td className="ft-Wt-700"> 110 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>(Samosa 2 Pc + Chole + Chutney)</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Khasta Kachori Chat</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>(Kachori 1 Pc + Dahi + Chutney)</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Misal Pav </td>
          <td className="ft-Wt-700"> 120 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>(Misal + Pav 2 Pcs + Onion + Lemon)</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Rajdhani Executive Thali</td>
          <td className="ft-Wt-700"> 280 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>
            (Farsan + 2 Veg + 3 Chapati + Dal + Rice + 1 Sweet + Accompaniments)
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Paneer Combo Meal</td>
          <td className="ft-Wt-700"> 250 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>
            (Paneer Veg + 3 Chapati + Dal + Rice + 1 Sweet + Accompaniments)
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Chole Puri </td>
          <td className="ft-Wt-700"> 180 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>(Chole + Puri 5 Pcs + Accompaniments)</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Puri Bhaji  </td>
          <td className="ft-Wt-700"> 150 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>(Aloo Sabzi + Puri 5 Pcs +  Accompaniments)</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Masala Chaas</td>
          <td className="ft-Wt-700"> 60 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>Maharaja Lassi</td>
          <td className="ft-Wt-700"> 100 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>Extra Pav - 1 Pc</td>
          <td className="ft-Wt-700"> 10 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>Gulab Jamun - 2 Pcs</td>
          <td className="ft-Wt-700"> 50 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr className="title">
          <td colSpan={3}>Society Tea Terminal</td>
        </tr>
        <tr className="head">
          <td>Menu</td>
          <td> MRP </td>
          <td></td>
        </tr>
        <tr>
          <td>REGULAR TEA </td>
          <td className="ft-Wt-700"> 50.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>MASALA TEA </td>
          <td className="ft-Wt-700"> 50.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>ELAICHI TEA </td>
          <td className="ft-Wt-700"> 50.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>ICED TEA</td>
          <td className="ft-Wt-700"> 80.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>CAPPUCCINO</td>
          <td className="ft-Wt-700"> 60.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>COLD COFFEE</td>
          <td className="ft-Wt-700"> 100.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>KHARI </td>
          <td className="ft-Wt-700"> 40.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>TOAST </td>
          <td className="ft-Wt-700"> 40.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>BUN MASKA JAM (1 PC)</td>
          <td className="ft-Wt-700"> 60.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>GREEN TEA BAGS</td>
          <td className="ft-Wt-700"> 60.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>CLEANSE</td>
          <td className="ft-Wt-700"> 60.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>HALDI DOODH</td>
          <td className="ft-Wt-700"> 60.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>POHA</td>
          <td className="ft-Wt-700"> 70.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>UPMA</td>
          <td className="ft-Wt-700"> 70.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>SANDWICH VEG</td>
          <td className="ft-Wt-700"> 100.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>VEG PUFF</td>
          <td className="ft-Wt-700"> 40.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>CHEESE PUFF</td>
          <td className="ft-Wt-700"> 50.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>SAMOSA ( 1 PC)</td>
          <td className="ft-Wt-700"> 40.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>VADA </td>
          <td className="ft-Wt-700"> 40.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td>PAV</td>
          <td className="ft-Wt-700"> 10.00 </td>
          <td className="ft-Wt-700"></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr className="title">
          <td colSpan={3}>BEVERAGE COUNTER</td>
        </tr>
        <tr className="head">
          <td>Menu</td>
          <td> MRP ( PER BTL) </td>
          <td> MRP (CASE) </td>
        </tr>
        <tr>
          <td>BISLERI MINERAL WATER 1 LTR (12 NOS)</td>
          <td className="ft-Wt-700"> 20.00 </td>
          <td className="ft-Wt-700" > 240.00 </td>
        </tr>
        <tr>
          <td>BISLERI MINERAL WATER 250 ML (48 NOS)</td>
          <td className="ft-Wt-700"> 10.00 </td>
          <td className="ft-Wt-700" > 290.00 </td>
        </tr>
        <tr>
          <td>BISLERI LIMONATA 160 ML (24 NOS)</td>
          <td className="ft-Wt-700"> 10.00 </td>
          <td className="ft-Wt-700" > 240.00 </td>
        </tr>
        <tr>
          <td>BISLERI POP - ORANGE 160 ML (24 NOS)</td>
          <td className="ft-Wt-700"> 10.00 </td>
          <td className="ft-Wt-700" > 240.00 </td>
        </tr>
        <tr>
          <td>COKE OR THUMPS UP 250 ML (24 NOS)</td>
          <td className="ft-Wt-700"> 20.00 </td>
          <td className="ft-Wt-700" > 480.00 </td>
        </tr>
        <tr>
          <td>LIMCA OR SLICE 250 ML ( 24 NOS)</td>
          <td className="ft-Wt-700"> 20.00 </td>
          <td className="ft-Wt-700" > 480.00</td>
        </tr>
        <tr className="title">
          <td colSpan={3}>LOKAL</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>LOKAL VEG THALI</td>
          <td className="ft-Wt-700"> 280.00 </td>
        </tr>
        <tr>
          <td>LOKAL THEPLA</td>
          <td className="ft-Wt-700"> 70.00 </td>
        </tr>
        <tr>
          <td>JALEBI &amp; FAFDA</td>
          <td className="ft-Wt-700"> 135.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>SHIV SAGAR</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>INDIAN EXPRESS COMBO</td>
          <td className="ft-Wt-700"> 300.00 </td>
        </tr>
        <tr>
          <td>PAV BHAJI (J)</td>
          <td className="ft-Wt-700"> 150.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>CHETTINAD EXPRESS</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>DOSA : SADA </td>
          <td className="ft-Wt-700"> 90.00 </td>
        </tr>
        <tr>
          <td>DOSA : MASALA </td>
          <td className="ft-Wt-700"> 120.00 </td>
        </tr>
        <tr>
          <td>DOSA : MYSORE MASALA</td>
          <td className="ft-Wt-700"> 140.00 </td>
        </tr>
        <tr>
          <td>DOSA : MYSORE SADA </td>
          <td className="ft-Wt-700"> 120.00 </td>
        </tr>
        <tr>
          <td>IDLI WADA PLATE</td>
          <td className="ft-Wt-700"> 80.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>WOW MOMOS</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>VEG DARJEELING STEAM MOMO (Steamed)</td>
          <td className="ft-Wt-700"> 160.00 </td>
        </tr>
        <tr>
          <td>PANEER STEAM MOMO (Steamed)</td>
          <td className="ft-Wt-700"> 190.00 </td>
        </tr>
        <tr>
          <td>CORN CHEESE STEAM MOMO (Steamed)</td>
          <td className="ft-Wt-700"> 210.00 </td>
        </tr>
        <tr>
          <td>VEG DARJEELING FRIED MOMO (Fried)</td>
          <td className="ft-Wt-700"> 160.00 </td>
        </tr>
        <tr>
          <td>PANEER FRIED MOMO (Fried)</td>
          <td className="ft-Wt-700"> 190.00 </td>
        </tr>
        <tr>
          <td>CORN CHEESE FRIED MOMO (Fried)</td>
          <td className="ft-Wt-700"> 210.00 </td>
        </tr>
        <tr>
          <td>VEG DARJEELING PAN FRIED MOMO</td>
          <td className="ft-Wt-700"> 210.00 </td>
        </tr>
        <tr>
          <td>PANEER PAN FRIED MOMO</td>
          <td className="ft-Wt-700"> 220.00 </td>
        </tr>
        <tr>
          <td>CORN CHEESE PAN FRIED MOMO</td>
          <td className="ft-Wt-700"> 260.00 </td>
        </tr>
        <tr>
          <td>CHIILLY PANEER DRY</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>FRIED RICE WITH CHILLI PANEER</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>FRIED RICE WITH VEG MANCHURIAN</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>HAKKA NOODLES WITH CHILLI PANEER</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>HAKKA NOODLES WITH VEG MANCHURIAN</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>COCO CHARGE</td>
          <td className="ft-Wt-700"> 100.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>QUICK BITES</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>CHOLE KULCHA (J)</td>
          <td className="ft-Wt-700"> 150.00 </td>
        </tr>
        <tr>
          <td>SAMOSA (J)</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>VADA PAV (J)</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>CHOLE KULCHA</td>
          <td className="ft-Wt-700"> 150.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>Society Tea Terminal</td>
        </tr>
        <tr className="head">
          <td>Menu</td>
          <td> MRP </td>
        </tr>
        <tr>
          <td>REGULAR TEA </td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>
        <tr>
          <td>MASALA TEA </td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>
        <tr>
          <td>ELAICHI TEA </td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>
        <tr>
          <td>ICED TEA</td>
          <td className="ft-Wt-700"> 80.00 </td>
        </tr>
        <tr>
          <td>CAPPUCCINO</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>COLD COFFEE</td>
          <td className="ft-Wt-700"> 100.00 </td>
        </tr>
        <tr>
          <td>KHARI 2PC</td>
          <td className="ft-Wt-700"> 40.00 </td>
        </tr>
        <tr>
          <td>TOAST 2PC</td>
          <td className="ft-Wt-700"> 40.00 </td>
        </tr>
        <tr>
          <td>BUN MASKA JAM (1 PC)</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>GREEN TEA BAGS</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>CLEANSE</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>HALDI DOODH</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>POHA</td>
          <td className="ft-Wt-700"> 70.00 </td>
        </tr>
        <tr>
          <td>UPMA</td>
          <td className="ft-Wt-700"> 70.00 </td>
        </tr>
        <tr>
          <td>SANDWICH VEG</td>
          <td className="ft-Wt-700"> 100.00 </td>
        </tr>
        <tr>
          <td>VEG PUFF</td>
          <td className="ft-Wt-700"> 40.00 </td>
        </tr>
        <tr>
          <td>CHEESE PUFF</td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>
        <tr>
          <td>SAMOSA ( 1 PC)</td>
          <td className="ft-Wt-700"> 40.00 </td>
        </tr>
        <tr>
          <td>VADA </td>
          <td className="ft-Wt-700"> 40.00 </td>
        </tr>
        <tr>
          <td>PAV</td>
          <td className="ft-Wt-700"> 10.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>BEVERAGE COUNTER</td>
        </tr>
        <tr className="head">
          <td>Menu</td>
          <td> MRP ( PER BTL) </td>
        </tr>
        <tr>
          <td>BISLERI MINERAL WATER 1 LTR (12 NOS)</td>
          <td className="ft-Wt-700"> 20.00 </td>
        </tr>
        <tr>
          <td>BISLERI MINERAL WATER 250 ML (48 NOS)</td>
          <td className="ft-Wt-700"> 10.00 </td>
        </tr>
        <tr>
          <td>BISLERI LIMONATA 160 ML (24 NOS)</td>
          <td className="ft-Wt-700"> 10.00 </td>
        </tr>
        <tr>
          <td>BISLERI POP - ORANGE 160 ML (24 NOS)</td>
          <td className="ft-Wt-700"> 10.00 </td>
        </tr>
        <tr>
          <td>COKE OR THUMPS UP 250 ML (24 NOS)</td>
          <td className="ft-Wt-700"> 20.00 </td>
        </tr>
        <tr>
          <td>LIMCA OR SLICE 250 ML ( 24 NOS)</td>
          <td className="ft-Wt-700"> 20.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>TIBBS</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>VEG FRANKIE</td>
          <td className="ft-Wt-700"> 160.00 </td>
        </tr>
        <tr>
          <td>TAWA PANEER</td>
          <td className="ft-Wt-700"> 160.00</td>
        </tr>
      </table>
    );
  },
  foodCourt: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>LOKAL</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>LOKAL VEG THALI</td>
          <td className="ft-Wt-700"> 280.00 </td>
        </tr>
        <tr>
          <td>LOKAL THEPLA</td>
          <td className="ft-Wt-700"> 70.00 </td>
        </tr>
        <tr>
          <td>JALEBI &amp; FAFDA</td>
          <td className="ft-Wt-700"> 135.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>SHIV SAGAR</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>PAVBHAJI </td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>SADA DOSA</td>
          <td className="ft-Wt-700"> 100.00 </td>
        </tr>
        <tr>
          <td>MASALA DOSA</td>
          <td className="ft-Wt-700"> 120.00 </td>
        </tr>
        <tr>
          <td>MYSORE SADA</td>
          <td className="ft-Wt-700"> 130.00 </td>
        </tr>
        <tr>
          <td>MYSORE MASALA </td>
          <td className="ft-Wt-700"> 150.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}> CHHOLAY</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>CHOLE KULCHA</td>
          <td className="ft-Wt-700"> 190.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>QUENCH</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>TEA </td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>
        <tr>
          <td>COFFEE</td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>
        <tr>
          <td>VADA PAV </td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>SAMOSA </td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>WOW MOMOS</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>VEG DARJEELING STEAM MOMO (Steamed) 5pcs.</td>
          <td className="ft-Wt-700"> 160.00 </td>
        </tr>
        <tr>
          <td>PANEER STEAM MOMO (Steamed) 5pcs.</td>
          <td className="ft-Wt-700"> 190.00 </td>
        </tr>
        <tr>
          <td>CORN CHEESE STEAM MOMO (Steamed) 5pcs.</td>
          <td className="ft-Wt-700"> 210.00 </td>
        </tr>
        <tr>
          <td>VEG DARJEELING FRIED MOMO (Fried) 5pcs.</td>
          <td className="ft-Wt-700"> 160.00 </td>
        </tr>
        <tr>
          <td>PANEER FRIED MOMO (Fried) 5pcs.</td>
          <td className="ft-Wt-700"> 190.00 </td>
        </tr>
        <tr>
          <td>CORN CHEESE FRIED MOMO (Fried) 5pcs.</td>
          <td className="ft-Wt-700"> 210.00 </td>
        </tr>
        <tr>
          <td>VEG DARJEELING PAN FRIED MOMO 5pcs.</td>
          <td className="ft-Wt-700"> 210.00 </td>
        </tr>
        <tr>
          <td>PANEER PAN FRIED MOMO 5pcs</td>
          <td className="ft-Wt-700"> 220.00 </td>
        </tr>
        <tr>
          <td>CORN CHEESE PAN FRIED MOMO 5pcs.</td>
          <td className="ft-Wt-700"> 260.00 </td>
        </tr>
        <tr>
          <td>CHIILLY PANEER DRY</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>FRIED RICE WITH CHILLI PANEER</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>FRIED RICE WITH VEG MANCHURIAN</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>HAKKA NOODLES WITH CHILLI PANEER</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>HAKKA NOODLES WITH VEG MANCHURIAN</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr className="head">
          <td>COCO CHARGE</td>
          <td className="ft-Wt-700"> 100.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>BEVERAGE COUNTER</td>
        </tr>
        <tr className="head">
          <td>Menu</td>
          <td> MRP ( PER BTL) </td>
        </tr>
        <tr>
          <td>BISLERI MINERAL WATER 1 LTR (12 NOS)</td>
          <td className="ft-Wt-700"> 20.00 </td>
        </tr>
        <tr>
          <td>BISLERI MINERAL WATER 250 ML (48 NOS)</td>
          <td className="ft-Wt-700"> 10.00 </td>
        </tr>
        <tr>
          <td>BISLERI LIMONATA 160 ML (24 NOS)</td>
          <td className="ft-Wt-700"> 10.00 </td>
        </tr>
        <tr>
          <td>BISLERI POP - ORANGE 160 ML (24 NOS)</td>
          <td className="ft-Wt-700"> 10.00 </td>
        </tr>
        <tr>
          <td>COKE OR THUMPS UP 250 ML (24 NOS)</td>
          <td className="ft-Wt-700"> 20.00 </td>
        </tr>
        <tr>
          <td>LIMCA OR SLICE 250 ML ( 24 NOS)</td>
          <td className="ft-Wt-700"> 20.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>Society Tea Terminal</td>
        </tr>
        <tr className="head">
          <td>Menu</td>
          <td> MRP </td>
        </tr>
        <tr>
          <td>REGULAR TEA </td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>
        <tr>
          <td>MASALA TEA </td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>
        <tr>
          <td>ELAICHI TEA </td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>
        <tr>
          <td>ICED TEA</td>
          <td className="ft-Wt-700"> 80.00 </td>
        </tr>
        <tr>
          <td>CAPPUCCINO</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>COLD COFFEE</td>
          <td className="ft-Wt-700"> 100.00 </td>
        </tr>
        <tr>
          <td>KHARI 2PC</td>
          <td className="ft-Wt-700"> 40.00 </td>
        </tr>
        <tr>
          <td>TOAST 2PC</td>
          <td className="ft-Wt-700"> 40.00 </td>
        </tr>
        <tr>
          <td>BUN MASKA JAM (1 PC)</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>GREEN TEA BAGS</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>CLEANSE</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>HALDI DOODH</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>POHA</td>
          <td className="ft-Wt-700"> 70.00 </td>
        </tr>
        <tr>
          <td>UPMA</td>
          <td className="ft-Wt-700"> 70.00 </td>
        </tr>
        <tr>
          <td>SANDWICH VEG</td>
          <td className="ft-Wt-700"> 100.00 </td>
        </tr>
        <tr>
          <td>VEG PUFF</td>
          <td className="ft-Wt-700"> 40.00 </td>
        </tr>
        <tr>
          <td>CHEESE PUFF</td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>
        <tr>
          <td>SAMOSA ( 1 PC)</td>
          <td className="ft-Wt-700"> 40.00 </td>
        </tr>
        <tr>
          <td>VADA </td>
          <td className="ft-Wt-700"> 40.00 </td>
        </tr>
        <tr>
          <td>PAV</td>
          <td className="ft-Wt-700"> 10.00</td>
        </tr>
        <tr className="title">
          <td colSpan={3}>MAHARAJA BHOG</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>EXECUTIVE BOX</td>
          <td className="ft-Wt-700"> 300.00 </td>
        </tr>
        <tr>
          <td>RING DHOKLA WITH COCONUT CHUTNEY</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>TEA</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>VEG BIRYANI WITH RAITA, PAPAD &amp; GULAB JAMUN</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>THEPLA , BHAJI &amp; GULAB JAMUN</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>PAV BHAJI</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>EXPRESS BOX</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>SHIV SAGAR</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>PAV BHAJI (J)</td>
          <td className="ft-Wt-700"> 150.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>R.S.MANI </td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td> DOSA SADA </td>
          <td className="ft-Wt-700"> 100.00 </td>
        </tr>
        <tr>
          <td> DOSA MASALA </td>
          <td className="ft-Wt-700"> 120.00 </td>
        </tr>
        <tr>
          <td> BUTTER SADA DOSA </td>
          <td className="ft-Wt-700"> 120.00 </td>
        </tr>
        <tr>
          <td> GHEE ROAST </td>
          <td className="ft-Wt-700"> 140.00 </td>
        </tr>
        <tr>
          <td> MYSORE SADA DOSA </td>
          <td className="ft-Wt-700"> 120.00 </td>
        </tr>
        <tr>
          <td> MYSORE MASALA DOSA </td>
          <td className="ft-Wt-700"> 140.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>CHINA TIME</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>MEAL - VEG FRIED RICE</td>
          <td className="ft-Wt-700"> 180.00 </td>
        </tr>
        <tr>
          <td>Paneer Veg Chilly in Basil Sauce</td>
          <td className="ft-Wt-700"> 180.00 </td>
        </tr>
        <tr>
          <td>Veg Combo</td>
          <td className="ft-Wt-700"> 260.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>BEVERAGE COUNTER</td>
        </tr>
        <tr className="head">
          <td>Menu</td>
          <td> MRP ( PER BTL) </td>
        </tr>
        <tr>
          <td>BISLERI MINERAL WATER 1 LTR (12 NOS)</td>
          <td className="ft-Wt-700"> 20.00 </td>
        </tr>
        <tr>
          <td>BISLERI MINERAL WATER 250 ML (48 NOS)</td>
          <td className="ft-Wt-700"> 10.00 </td>
        </tr>
        <tr>
          <td>BISLERI LIMONATA 160 ML (24 NOS)</td>
          <td className="ft-Wt-700"> 10.00 </td>
        </tr>
        <tr>
          <td>BISLERI POP - ORANGE 160 ML (24 NOS)</td>
          <td className="ft-Wt-700"> 10.00 </td>
        </tr>
        <tr>
          <td>COKE OR THUMPS UP 250 ML (24 NOS)</td>
          <td className="ft-Wt-700"> 20.00 </td>
        </tr>
        <tr>
          <td>LIMCA OR SLICE 250 ML ( 24 NOS)</td>
          <td className="ft-Wt-700"> 20.00</td>
        </tr>
      </table>
    );
  },
  jioCafe1: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>Grab &amp; Go</td>
        </tr>
        <tr className="head">
          <td>Items</td>
          <td>Rate</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>COOKIES</td>
        </tr>
        <tr>
          <td>White chocolate &amp; cashew cookies </td>
        </tr>
        <tr>
          <td>(Contains Wheat, Nut, Milk, Soya)</td>
        </tr>
        <tr>
          <td>Chocolate chip cookies </td>
        </tr>
        <tr>
          <td>(Contains Wheat, Milk, Soya)</td>
          <td></td>
        </tr>
        <tr>
          <td>Any 2 Pieces</td>
          <td className="ft-Wt-700">70</td>
        </tr>
        <tr>
          <td>Any 1 Piece</td>
          <td className="ft-Wt-700">40</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>SAVORIES</td>
        </tr>
        <tr>
          <td>Cheese and Chutney Sandwich (Jain)</td>
        </tr>
        <tr>
          <td>(Conatins Wheat, Milk)</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>Corn and Jalapeno Sandwich</td>
        </tr>
        <tr>
          <td>(Contains Wheat, Milk)</td>
          <td className="ft-Wt-700">200</td>
        </tr>
        <tr>
          <td>Choice of Grilled / Toast Sandwish</td>
          <td></td>
        </tr>
        <tr>
          <td>Samosa (2 Nos.) (Jain)</td>
        </tr>
        <tr>
          <td>(Contains Wheat, Milk)</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>Masala Dal Kachori (2 Nos.)(Jain)</td>
        </tr>
        <tr>
          <td>(Contains Wheat, Milk)</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>HOT BEVERAGES</td>
        </tr>
        <tr>
          <td>Readymade Masala Tea</td>
          <td className="ft-Wt-700">70</td>
        </tr>
        <tr>
          <td>Readymade Coffee</td>
          <td className="ft-Wt-700">70</td>
        </tr>
        <tr>
          <td>Espresso</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>Café Latte</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>Cappuccino</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>Americano</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>COLD BEVERAGES</td>
        </tr>
        <tr>
          <td>Anando Flavoured Milk (180 ML)</td>
          <td className="ft-Wt-700">ON MRP</td>
        </tr>
        <tr>
          <td>Anando Cold Coffee (200 ML)</td>
          <td className="ft-Wt-700">ON MRP</td>
        </tr>
        <tr>
          <td>Areated Beverage (330 ML)</td>
          <td className="ft-Wt-700">ON MRP</td>
        </tr>
        <tr>
          <td>AVA Mineral Water (200 ML X 24 nos.)</td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>AVA Mineral Water (500 ML)</td>
          <td className="ft-Wt-700">30</td>
        </tr>
        <tr>
          <td>AVA Mineral Water (1000 ML)</td>
          <td className="ft-Wt-700">50</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>New addition </td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>Prepackaged Treats </td>
        </tr>
        <tr>
          <td>Crisp wafer potatoes (35 Gms Lays) </td>
          <td className="ft-Wt-700">10</td>
        </tr>
      </table>
    );
  },
  jioCafe2: () => {
    return (
      <table class="title">
        <tr>
          <td colSpan="2">Coffee Shop Menu</td>
        </tr>
        <tr>
          <td class="subHead" colSpan="2">Cookies</td>
        </tr>
        <tr>
          <td>Peanut butter with sesame cookie</td>
          <td></td>
        </tr>
        <tr>
          <td>Oatmeal chocolate chip cookie</td>
          <td class="ft-Wt-700">ANY 2 PIECES...₹70<br />Single Piece... ₹40</td>
        </tr>
        <tr>
          <td class="subHead" colSpan="2">Savory</td>
        </tr>
        <tr>
          <td>Crisp Kaju mutter samosa</td>
          <td class="ft-Wt-700">₹150 (Jain) – 2pcs</td>
        </tr>
        <tr>
          <td>Vadapav</td>
          <td class="ft-Wt-700">₹150 – 2pcs</td>
        </tr>
        <tr>
          <td class="subHead" colSpan="2">Hot Beverages</td>
        </tr>
        <tr>
          <td>Readymade masala tea</td>
          <td class="ft-Wt-700">₹70</td>
        </tr>
        <tr>
          <td>Readymade coffee</td>
          <td class="ft-Wt-700">₹70</td>
        </tr>
        <tr>
          <td>Choice of espresso, Café latte, Cappuccino</td>
          <td class="ft-Wt-700">₹150</td>
        </tr>
        <tr>
          <td class="subHead" colSpan="2">Menu Food Court Kiosk – Grab & Go</td>
        </tr>
        <tr>
          <td>Chutney and cheese sandwich</td>
          <td class="ft-Wt-700">₹150</td>
        </tr>
        <tr>
          <td>Corn and jalapeno sandwich</td>
          <td class="ft-Wt-700">₹200</td>
        </tr>
        <tr>
          <td class="subHead" colSpan="2">Prepackaged Treats</td>
        </tr>
        <tr>
          <td>Crisp wafer potatoes (35 Gms Lays)</td>
          <td class="ft-Wt-700">₹10 (MRP)</td>
        </tr>
        <tr>
          <td>Milk shakes (180 ml)</td>
          <td class="ft-Wt-700">(MRP)</td>
        </tr>
        <tr>
          <td>Anandos Cold coffee (200 ml)</td>
          <td class="ft-Wt-700">(MRP)</td>
        </tr>
        <tr>
          <td>Aerated beverages (330ml)</td>
          <td class="ft-Wt-700">(MRP)</td>
        </tr>
        <tr>
          <td class="subHead" colSpan="2">Mineral Water Bottle and Cases</td>
        </tr>
        <tr>
          <td>Aava (500 ml)</td>
          <td></td>
        </tr>
        <tr>
          <td>Aava (1000 ml)</td>
          <td class="ft-Wt-700">₹30</td>
        </tr>
        <tr>
          <td>Aava (200 ml)</td>
          <td class="ft-Wt-700">₹250 (24 nos.)</td>
        </tr>
        <tr>
          <td>Aava (500 ml)</td>
          <td class="ft-Wt-700">₹500 (20 nos.)</td>
        </tr>
      </table>

    );
  },
  jioCafe3: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan="3">Cafeteria menu</td>
        </tr>
        <tr>
          <td></td>
          <td class="head">Item</td>
          <td class="head">Amount</td>
        </tr>
        <tr>
          <td class="subHead" colSpan="3">Grab & Go</td>
        </tr>
        <tr>
          <td></td>
          <td>Peanut butter with sesame cookie</td>
          <td></td>
        </tr>
        <tr>
          <td>Oatmeal chocolate chip cookie</td>
          <td class="ft-Wt-700">ANY 2 PIECES...₹70<br />Single Piece... ₹40</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Crisp Kaju mutter samosa</td>
          <td class="ft-Wt-700">₹150 (Jain) – 2pcs</td>
        </tr>
        <tr>
          <td></td>
          <td>Vadapav</td>
          <td class="ft-Wt-700">₹150 – 2pcs</td>
        </tr>
        <tr>
          <td></td>
          <td>Readymade masala tea</td>
          <td class="ft-Wt-700">₹70</td>
        </tr>
        <tr>
          <td></td>
          <td>Readymade coffee</td>
          <td class="ft-Wt-700">₹70</td>
        </tr>
        <tr>
          <td></td>
          <td>Choice of espresso, Café latte, Cappuccino</td>
          <td class="ft-Wt-700">₹150</td>
        </tr>
        <tr>
          <td></td>
          <td>Chutney and cheese sandwich</td>
          <td class="ft-Wt-700">₹150</td>
        </tr>
        <tr>
          <td></td>
          <td>Corn and jalapeno sandwich</td>
          <td class="ft-Wt-700">₹200</td>
        </tr>
        <tr>
          <td></td>
          <td>Crisp wafer potatoes (35 Gms Lays)</td>
          <td class="ft-Wt-700">₹10 (MRP)</td>
        </tr>
        <tr>
          <td></td>
          <td>Milk shakes (180 ml)</td>
          <td class="ft-Wt-700">(MRP)</td>
        </tr>
        <tr>
          <td></td>
          <td>Anandos Cold coffee (200 ml)</td>
          <td class="ft-Wt-700">(MRP)</td>
        </tr>
        <tr>
          <td></td>
          <td>Aerated beverages (330ml)</td>
          <td class="ft-Wt-700">(MRP)</td>
        </tr>
        <tr>
          <td></td>
          <td>Mineral water bottle and cases</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Aava (500 ml)</td>
          <td></td>
        </tr>
        <tr>
          <td>Aava (1000 ml)</td>
          <td class="ft-Wt-700">₹30</td>
          <td></td>
        </tr>
        <tr>
          <td class="ft-Wt-700">₹50</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Aava (200 ml)</td>
          <td class="ft-Wt-700">₹250 (24 nos.)</td>
        </tr>
        <tr>
          <td></td>
          <td>Aava (500 ml)</td>
          <td class="ft-Wt-700">₹500 (20 nos.)</td>
        </tr>

        <tr class="subHead">
          <td colSpan="3">Tithi Food (11AM – 7:30 PM) Only on 9th August 2024</td>
        </tr>
        <tr>
          <td></td>
          <td>Dahi wada</td>
          <td></td>
        </tr>
        <tr>
          <td>Kachori</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Khaman dhokla</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Rajma</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Steamed fragrant basmati rice</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Water bottle</td>
          <td class="ft-Wt-700">₹400</td>
          <td></td>
        </tr>

        <tr class="subHead">
          <td colSpan="3">SOUTHERN FLAVOURS</td>
        </tr>
        <tr>
          <td>7</td>
          <td>UTTAPAM & DOSAI STAGE (TIME: 11 AM – 7.30 PM)</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Butter Sada Dosa</td>
          <td class="ft-Wt-700">₹150</td>
        </tr>
        <tr>
          <td></td>
          <td>Butter Masala Dosa</td>
          <td class="ft-Wt-700">₹200</td>
        </tr>
        <tr>
          <td></td>
          <td>Onion Gunpowder Uttapam</td>
          <td class="ft-Wt-700">₹180</td>
        </tr>
        <tr>
          <td></td>
          <td>Mysore Butter Masala Dosa</td>
          <td class="ft-Wt-700">₹250</td>
        </tr>

        <tr>
          <td>8</td>
          <td>MUMBAI STREET (TIME: 11 AM – 07.30 PM)</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Misal pav</td>
          <td class="ft-Wt-700">₹180</td>
        </tr>
        <tr>
          <td></td>
          <td>Pav bhaji</td>
          <td class="ft-Wt-700">₹180</td>
        </tr>
        <tr>
          <td></td>
          <td>Martabaan ke Chole Kulche</td>
          <td class="ft-Wt-700">₹220 (Jain)</td>
        </tr>
        <tr>
          <td></td>
          <td>Biryani from 12pm to 4pm</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Choice Meal Subz Biryani with Mirch Ka Salan, Gulab Jamun</td>
          <td class="ft-Wt-700">₹300</td>
        </tr>

        <tr>
          <td>9</td>
          <td>PIZZA & PASTA</td>
          <td>PIZZA</td>
        </tr>
        <tr>
          <td></td>
          <td>Margarita – JAIN</td>
          <td class="ft-Wt-700">₹250</td>
        </tr>
        <tr>
          <td></td>
          <td>Olives, Sundried Tomato and Arugula</td>
          <td class="ft-Wt-700">₹250</td>
        </tr>
        <tr>
          <td></td>
          <td>Penne in a choice of sauces:</td>
          <td></td>
        </tr>
        <tr>
          <td>“Arrabiatta” style mascarpone & tomato crème,</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Soused garden vegetables</td>
          <td class="ft-Wt-700">₹250</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>“Alfredo” in Grana Padano cream, walnut pesto</td>
          <td class="ft-Wt-700">₹250</td>
        </tr>

        <tr>
          <td>11</td>
          <td>CHAAT COUNTER</td>
          <td>(TIME: 12 PM – 07.30 PM) (Jain) options available</td>
        </tr>
        <tr>
          <td></td>
          <td>Sev puri</td>
          <td class="ft-Wt-700">₹100</td>
        </tr>
        <tr>
          <td></td>
          <td>Samosa chaat</td>
          <td class="ft-Wt-700">₹100</td>
        </tr>
        <tr>
          <td></td>
          <td>Ragda pattice</td>
          <td class="ft-Wt-700">₹100</td>
        </tr>
        <tr>
          <td></td>
          <td>Papdi chaat</td>
          <td class="ft-Wt-700">₹100</td>
        </tr>
        <tr>
          <td></td>
          <td>Mumbai geeli bhel poori</td>
          <td class="ft-Wt-700">₹100</td>
        </tr>

        <tr>
          <td>12</td>
          <td>WHOLESOME MEALS</td>
          <td>TIME: 12 PM - 4 PM</td>
        </tr>
        <tr>
          <td></td>
          <td>INDIAN VEG MEAL (JAIN)</td>
          <td class="ft-Wt-700">₹400</td>
        </tr>
        <tr>
          <td></td>
          <td>INDIAN VEG MEAL (REGULAR)</td>
          <td class="ft-Wt-700">₹400</td>
        </tr>
        <tr>
          <td></td>
          <td>(Contains milk, nut, wheat)</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Paneer preparation of the day</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Dal of the day</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Vegetarian dish of the day</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Laccha Paratha</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Pulao dish of the day</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Dessert of the day</td>
          <td></td>
        </tr>

        <tr>
          <td></td>
          <td>ASIAN VEG MEAL</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>(Contains milk, nut, soy, wheat)</td>
          <td class="ft-Wt-700">₹400</td>
        </tr>
        <tr>
          <td></td>
          <td>Cottage cheese preparation of the day</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Vegetable dish of the day</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Burnt Garlic Fried Rice</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Salad of “Thai Style” Young Papaya</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Dessert of the day</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Snacks Counter</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Jalebi fafda</td>
          <td class="ft-Wt-700">₹250</td>
        </tr>
        <tr>
          <td></td>
          <td>Mix Pakoda with Chai</td>
          <td class="ft-Wt-700">₹250</td>
        </tr>
        <tr>
          <td></td>
          <td>Vegetable Upma with Chutney</td>
          <td class="ft-Wt-700">₹100</td>
        </tr>
      </table>

    );
  },
  jioCafe4: () => {
    return (
      <table>
        <tr class="title">
          <td colSpan="2">Coffee Shop Menu</td>
        </tr>
        <tr>
          <td class="subHead" colSpan="2">Cookies</td>
        </tr>
        <tr>
          <td>Peanut butter with sesame cookie</td>
          <td></td>
        </tr>
        <tr>
          <td>Oatmeal chocolate chip cookie</td>
          <td class="ft-Wt-700">ANY 2 PIECES...₹70<br />Single Piece... ₹40</td>
        </tr>
        <tr>
          <td class="subHead" colSpan="2">Savory</td>
        </tr>
        <tr>
          <td>Crisp Kaju mutter samosa</td>
          <td class="ft-Wt-700">₹150 (Jain) – 2pcs</td>
        </tr>
        <tr>
          <td>Vadapav</td>
          <td class="ft-Wt-700">₹150 – 2pcs</td>
        </tr>
        <tr>
          <td class="subHead" colSpan="2">Hot Beverages</td>
        </tr>
        <tr>
          <td>Readymade masala tea</td>
          <td class="ft-Wt-700">₹70</td>
        </tr>
        <tr>
          <td>Readymade coffee</td>
          <td class="ft-Wt-700">₹70</td>
        </tr>
        <tr>
          <td>Choice of espresso, Café latte, Cappuccino</td>
          <td class="ft-Wt-700">₹150</td>
        </tr>
        <tr>
          <td class="subHead" colSpan="2">Menu Food Court Kiosk – Grab & Go</td>
        </tr>
        <tr>
          <td>Chutney and cheese sandwich</td>
          <td class="ft-Wt-700">₹150</td>
        </tr>
        <tr>
          <td>Corn and jalapeno sandwich</td>
          <td class="ft-Wt-700">₹200</td>
        </tr>
        <tr>
          <td class="subHead" colSpan="2">Prepackaged Treats</td>
        </tr>
        <tr>
          <td>Crisp wafer potatoes (35 Gms Lays)</td>
          <td class="ft-Wt-700">₹10 (MRP)</td>
        </tr>
        <tr>
          <td>Milk shakes (180 ml)</td>
          <td class="ft-Wt-700">(MRP)</td>
        </tr>
        <tr>
          <td>Anandos Cold coffee (200 ml)</td>
          <td class="ft-Wt-700">(MRP)</td>
        </tr>
        <tr>
          <td>Aerated beverages (330ml)</td>
          <td class="ft-Wt-700">(MRP)</td>
        </tr>
        <tr>
          <td class="subHead" colSpan="2">Mineral Water Bottle and Cases</td>
        </tr>
        <tr>
          <td>Aava (500 ml)</td>
          <td></td>
        </tr>
        <tr>
          <td>Aava (1000 ml)</td>
          <td class="ft-Wt-700">₹30</td>
        </tr>
        <tr>
          <td>Aava (200 ml)</td>
          <td class="ft-Wt-700">₹250 (24 nos.)</td>
        </tr>
        <tr>
          <td>Aava (500 ml)</td>
          <td class="ft-Wt-700">₹500 (20 nos.)</td>
        </tr>
      </table>
    );
  },
  jioCafe5: () => {
    return (
      <table>
        <tr class="title">
          <td colSpan="3">Cafeteria Menu</td>
        </tr>
        <tr>
          <td class="subHead" colSpan="3">Grab & Go</td>
        </tr>
        <tr>
          <td>1</td>
          <td>Peanut butter with sesame cookie</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Oatmeal chocolate chip cookie</td>
          <td class="ft-Wt-700">ANY 2 PIECES...₹70<br />Single Piece... ₹40</td>
        </tr>
        <tr>
          <td></td>
          <td>Crisp Kaju mutter samosa</td>
          <td class="ft-Wt-700">₹150 (Jain) – 2pcs</td>
        </tr>
        <tr>
          <td></td>
          <td>Vadapav</td>
          <td class="ft-Wt-700">₹150 – 2pcs</td>
        </tr>
        <tr>
          <td></td>
          <td>Vegetable Upma with Chutney (Jain)</td>
          <td class="ft-Wt-700">₹100</td>
        </tr>
        <tr>
          <td></td>
          <td>Readymade masala tea</td>
          <td class="ft-Wt-700">₹70</td>
        </tr>
        <tr>
          <td></td>
          <td>Readymade coffee</td>
          <td class="ft-Wt-700">₹70</td>
        </tr>
        <tr>
          <td></td>
          <td>Choice of espresso, Café latte, Cappuccino</td>
          <td class="ft-Wt-700">₹150</td>
        </tr>
        <tr>
          <td></td>
          <td>Chutney and cheese sandwich</td>
          <td class="ft-Wt-700">₹150</td>
        </tr>
        <tr>
          <td></td>
          <td>Corn and jalapeno sandwich</td>
          <td class="ft-Wt-700">₹200</td>
        </tr>
        <tr>
          <td></td>
          <td>Crisp wafer potatoes (35 Gms Lays)</td>
          <td class="ft-Wt-700">₹10 (MRP)</td>
        </tr>
        <tr>
          <td></td>
          <td>Milk shakes (180 ml)</td>
          <td class="ft-Wt-700">(MRP)</td>
        </tr>
        <tr>
          <td></td>
          <td>Anandos Cold coffee (200 ml)</td>
          <td class="ft-Wt-700">(MRP)</td>
        </tr>
        <tr>
          <td></td>
          <td>Aerated beverages (330ml)</td>
          <td class="ft-Wt-700">(MRP)</td>
        </tr>
        <tr>
          <td></td>
          <td class="subHead">Mineral Water Bottle and Cases</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Aava (500 ml)</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Aava (1000 ml)</td>
          <td class="ft-Wt-700">₹30</td>
        </tr>
        <tr>
          <td></td>
          <td>Aava (200 ml)</td>
          <td class="ft-Wt-700">₹250 (24 nos.)</td>
        </tr>
        <tr>
          <td></td>
          <td>Aava (500 ml)</td>
          <td class="ft-Wt-700">₹500 (20 nos.)</td>
        </tr>
        <tr>
          <td class="subHead" colSpan="3">Tithi Food (11AM – 7:30 PM) Only on 9th August 2024</td>
        </tr>
        <tr>
          <td></td>
          <td>Dahi wada</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Kachori</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Khaman dhokla</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Rajma</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Steamed fragrant basmati rice</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Water bottle</td>
          <td class="ft-Wt-700">₹400</td>
        </tr>
        <tr>
          <td class="subHead" colSpan="3">Southern Flavours</td>
        </tr>
        <tr>
          <td>7</td>
          <td>UTTAPAM & DOSAI STAGE (TIME: 11 AM – 7.30 PM)</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Butter Sada Dosa</td>
          <td class="ft-Wt-700">₹150</td>
        </tr>
        <tr>
          <td></td>
          <td>Butter Masala Dosa</td>
          <td class="ft-Wt-700">₹200</td>
        </tr>
        <tr>
          <td></td>
          <td>Onion Gunpowder Uttapam</td>
          <td class="ft-Wt-700">₹180</td>
        </tr>
        <tr>
          <td></td>
          <td>Mysore Butter Masala Dosa</td>
          <td class="ft-Wt-700">₹250</td>
        </tr>
        <tr>
          <td class="subHead" colSpan="3">Mumbai Street (TIME: 11 AM – 07.30 PM)</td>
        </tr>
        <tr>
          <td>8</td>
          <td>Misal pav…</td>
          <td class="ft-Wt-700">₹180</td>
        </tr>
        <tr>
          <td></td>
          <td>Pav bhaji…</td>
          <td class="ft-Wt-700">₹180</td>
        </tr>
        <tr>
          <td></td>
          <td>Martabaan ke Chole Kulche</td>
          <td class="ft-Wt-700">₹220 (Jain)</td>
        </tr>
        <tr>
          <td></td>
          <td>Biryani from 12pm to 4pm</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Choice Meal Subz Biryani with Mirch Ka Salan, Gulab Jamun</td>
          <td class="ft-Wt-700">₹300</td>
        </tr>
        <tr>
          <td class="subHead" colSpan="3">Pizza & Pasta</td>
        </tr>
        <tr>
          <td>9</td>
          <td>PIZZA</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Margarita (JAIN)</td>
          <td class="ft-Wt-700">₹250</td>
        </tr>
        <tr>
          <td></td>
          <td>Olives, Sundried Tomato and Arugula</td>
          <td class="ft-Wt-700">₹250</td>
        </tr>
        <tr>
          <td></td>
          <td>Penne in a choice of sauces:</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>“Arrabiatta” style mascarpone & tomato crème, Soused garden vegetables</td>
          <td class="ft-Wt-700">₹250</td>
        </tr>
        <tr>
          <td></td>
          <td>“Alfredo” in Grana Padano cream, walnut pesto</td>
          <td class="ft-Wt-700">₹250</td>
        </tr>
        <tr>
          <td class="subHead" colSpan="3">Chaat Counter (TIME: 12 PM – 07.30 PM) (Jain options available)</td>
        </tr>
        <tr>
          <td>11</td>
          <td>Sev puri</td>
          <td class="ft-Wt-700">₹100</td>
        </tr>
        <tr>
          <td></td>
          <td>Samosa chaat</td>
          <td class="ft-Wt-700">₹100</td>
        </tr>
        <tr>
          <td></td>
          <td>Ragda pattice</td>
          <td class="ft-Wt-700">₹100</td>
        </tr>
        <tr>
          <td></td>
          <td>Papdi chaat</td>
          <td class="ft-Wt-700">₹100</td>
        </tr>
        <tr>
          <td></td>
          <td>Mumbai geeli bhel poori</td>
          <td class="ft-Wt-700">₹100</td>
        </tr>
        <tr>
          <td class="subHead" colSpan="3">Wholesome Meals</td>
        </tr>
        <tr>
          <td>12</td>
          <td>Indian Veg Meal (JAIN)</td>
          <td class="ft-Wt-700">₹400</td>
        </tr>
        <tr>
          <td></td>
          <td>Indian Veg Meal (Regular)</td>
          <td class="ft-Wt-700">₹400</td>
        </tr>
        <tr>
          <td></td>
          <td>(Contains milk, nut, wheat)</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Paneer preparation of the day</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Dal of the day</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Vegetarian dish of the day</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Laccha Paratha</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Pulao dish of the day</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Dessert of the day</td>
          <td></td>
        </tr>
        <tr>
          <td class="subHead" colSpan="3">Asian Veg Meal</td>
        </tr>
        <tr>
          <td></td>
          <td>(Contains milk, nut, soy, wheat)</td>
          <td class="ft-Wt-700">₹400</td>
        </tr>
        <tr>
          <td></td>
          <td>Cottage cheese preparation of the day</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Vegetable dish of the day</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Burnt Garlic Fried Rice</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Salad of “Thai Style” Young Papaya</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>Dessert of the day</td>
          <td></td>
        </tr>
      </table>


    );
  },

};
